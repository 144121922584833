import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import styled from "@mui/system/styled";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const SubscriptionDataTable = () => {
  const { ...rest } = useAuth();
  const [subscriptionsUrl, setSubscriptionsUrl] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [subscriptionData, setSubscriptionData] = useState({
    data: [],
    isLoading: true,
  });

  const [principlError, setPrincipalError] = useState({
    error: "",
    error_description: "",
  });

  const [checkPrincipalUrl, setCheckPrincipalUrl] = useState({
    subscription: null,
    subscriptionData: null,
    providerUrl: null,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const handleClick = (event, cellValues) => {
    checkPrincipal(cellValues.row);
  };

  const Item = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
    padding: theme.spacing(1),
    borderRadius: "4px",
    textAlign: "center",
  }));

  const columns = [
    { field: "friendlyName", headerName: "Display Name", width: 300 },
    { field: "name", headerName: "Subscription Name", width: 300 },
    {
      field: " ",
      renderCell: (cellValues) => {
        if (cellValues.row.selected === "1") {
          return (
            <ThemeProvider theme={theme}>
              <Tooltip title={cellValues.row.friendlyName}>
                <Typography
                  variant="h6"
                  color="primary"
                >
                  Active
                </Typography>
              </Tooltip>
            </ThemeProvider>
          );
        } else {
          return (
            <ThemeProvider theme={theme}>
              <Tooltip title={cellValues.row.friendlyName}>
                <Button
                  variant="outlined"
                  sx={hoverStyles}
                  color="primary"
                  onClick={(event) => {
                    handleClick(event, cellValues);
                  }}
                >
                  Switch
                </Button>
              </Tooltip>
            </ThemeProvider>
          );
        }
      },
      description: "Toggle active subscription",
    },
    { field: "subscription", headerName: "Subscription ID", width: 325 },
    { field: "tenant", headerName: "Tenant ID", width: 325 },
  ];
  const userTableStyles = {
    height: "1000px",
  };

  if (rest.provider !== "") {
    if (subscriptionsUrl === "") {
      setSubscriptionsUrl(rest.provider.url + "/api/subscriptions");
    }
  }

  const checkPrincipal = (row) => {
    //  Validate current SP
    if (rest.provider && rest.provider !== "") {
      setCheckPrincipalUrl({
        providerUrl: rest.provider.url,
        subscription: row.subscription,
        subscriptionData: row,
      });
    }
  };

  useEffect(
    () => {
      if (!tokenValid(rest.token)) {
        rest.setRefreshToken(true);
        return;
      }
      if (rest.subscription && rest.subscription === "") {
        return;
      }
      if (
        (checkPrincipalUrl.providerUrl !== null) &
        (checkPrincipalUrl.subscription !== null)
      ) {
        const checkUrl =
          checkPrincipalUrl.providerUrl +
          "/api/check_principal?subscription=" +
          checkPrincipalUrl.subscription;
        executeFetch("GET", checkUrl, rest.token).then((response) => {
          if (response) {
            if (response.isError) {
              console.log(response.error);
              setSnackbar({
                open: true,
                message: response.error,
                severity: "error",
              });
            } else {
              if (response.data.authorized !== undefined) {
                setPrincipalError({ error: "", error_description: "" });
                console.log(checkPrincipalUrl.subscriptionData);
                localStorage.setItem(
                  "subscription",
                  JSON.stringify(checkPrincipalUrl.subscriptionData)
                );
                window.location.reload();
              } else {
                setPrincipalError({
                  error: response.data.error,
                  error_description: response.data.error_description,
                });
              }
            }
          }
        });
      }
    },
    [checkPrincipalUrl],
    rest.subscription,
    rest.provider
  );

  useEffect(() => {
    if (!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (subscriptionsUrl === "") {
      return;
    }

    executeFetch("GET", subscriptionsUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          let data = response.data;
          if (data.length === 0) {
            localStorage.setItem("subscription", "");
          } else {
            data.forEach((element) => {
              element.selected = "0";
              if (rest.subscription && rest.subscription !== "") {
                if (element.subscription === rest.subscription.subscription) {
                  element.selected = "1";
                }
              }
            });
            if (!rest.subscription || rest.subscription === "") {
              data.every((element) => {
                element.selected = "1";
                localStorage.setItem("subscription", JSON.stringify(element));
                window.location.reload();
                return false;
              });
            }
          }

          setSubscriptionData({ data: response.data, isLoading: false });
        }
      }
    });
  }, [
    executeFetch,
    subscriptionsUrl,
    rest.subscription,
    rest.refreshToken,
  ]);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Grid
          container
          spacing={0}
        >
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <Item>
              <Typography variant="h4">Subscriptions</Typography>
            </Item>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
        >
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <Item>
              <Typography variant="subtitle1">{principlError.error}</Typography>
            </Item>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
        >
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <Item>
              <Typography variant="subtitle1">
                {principlError.error_description}
              </Typography>
            </Item>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <br />
        <DataGrid
          rows={subscriptionData.data}
          rowCount={subscriptionData.data.length}
          columns={columns}
          loading={subscriptionData.isLoading}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default SubscriptionDataTable;
