import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Tenant from "@mui/icons-material/RequestPage";

export const MenuTenant = () => {
  return (
    <div className="hover:bg-primary-main hover:text-white">
      <ListItemButton href="/tenantResources">
        <ListItemIcon>
          <Tooltip title="Tenant Resources">
            <Tenant />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Tenant" />
      </ListItemButton>
    </div>
  );
};
