import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const handleClick = (event, cellValues) => {
  localStorage.setItem("organization", JSON.stringify(cellValues.row));
  window.location.reload();
};

const columns = [
  { field: "id", hide: true },
  { field: "organization_id", headerName: "Organization ID", width: 300 },
  { field: "customer_id", headerName: "Customer ID", width: 300 },

  { field: "name", headerName: "Organization Name", width: 300 },
  {
    field: " ",
    renderCell: (cellValues) => {
      if (cellValues.row.selected === "1") {
        return (
          <ThemeProvider theme={theme}>
            <Tooltip title={cellValues.row.organization_id}>
              <Typography
                variant="h6"
                color="primary"
              >
                Active
              </Typography>
            </Tooltip>
          </ThemeProvider>
        );
      } else {
        return (
          <ThemeProvider theme={theme}>
            <Tooltip title={cellValues.row.organization_id}>
              <Button
                variant="outlined"
                sx={hoverStyles}
                color="primary"
                onClick={(event) => {
                  handleClick(event, cellValues);
                }}
              >
                Switch
              </Button>
            </Tooltip>
          </ThemeProvider>
        );
      }
    },
    description: "Toggle active organization",
  },
];
const userTableStyles = {
  height: "1000px",
};

const OrganizationDataTable = () => {
  const { ...rest } = useAuth();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [organizationData, setOrganizationData] = useState({
    data: [],
    isLoading: true,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const organizationUrl =
    providerUrl + "/customers/" + rest.customer.customer_id + "/organizations";

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    let currentOrganization = "";
    const organizationDatastore = localStorage.getItem("organization");

    if (organizationDatastore && organizationDatastore !== "undefined") {
      currentOrganization = JSON.parse(organizationDatastore);
    }

    executeFetch("GET", organizationUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
        } else {
          let data = response.data;
          if (data.length === 0) {
            currentOrganization = "";
            localStorage.removeItem("organization");
          } else {
            let index = 0;

            data.forEach((element) => {
              element.selected = "0";

              element.id = index;
              index = index + 1;
              if (currentOrganization !== "") {
                if (
                  element.organization_id ===
                  currentOrganization.organization_id
                ) {
                  element.selected = "1";
                }
              }
            });
            if (currentOrganization === "") {
              data.every((element) => {
                element.selected = "1";
                localStorage.setItem("organization", JSON.stringify(element));
                window.location.reload();
                return false;
              });
            }
          }

          setOrganizationData({ data: response.data, isLoading: false });
        }
      }
    });
  }, [organizationData.isLoading, organizationUrl, rest.refreshToken]);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <DataGrid
          rows={organizationData.data}
          rowCount={organizationData.data.length}
          columns={columns}
          loading={organizationData.isLoading}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
          columnVisibilityModel={{
            id: false,
          }}
        />
      </Box>
    </>
  );
};

export default OrganizationDataTable;
