import React, { useState } from "react";
import { Box, Tooltip, Typography, IconButton, Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { DataGrid, GridEditInputCell } from "@mui/x-data-grid";
import { useCallback } from "react";
import PopupModal from "../PopupModal/PopupModal";
import { hoverStyles } from "../../styles/theme";

const userTableStyles = {
  height: "800px",
};

const BulkAdd = ({
  open,
  onClose,
  tagSet,
  setTagSet,
  processChanges,
  title,
  subTitle,
  multiple,
  isAzure,
}) => {
  let defaultAddTag = {
    id: 0,
    name: "",
    value: "",
    modified: false,
    remove: false,
  };
  let defaultRemoveTag = {
    id: 0,
    name: "",
    value: "",
    modified: false,
    remove: true,
  };

  const validateTagName = (name) => {
    if (isAzure) {
      if (name.length > 512) {
        return "Tag name cannot exceed 512 characters";
      } else {
        const regex = /[\<\>\%\&\\\?\/]/g;
        if (name.match(regex)) {
          return "Characters <>%&\\?/ not allowed in tag name";
        } else {
          return null;
        }
      }
    } else {
      if (name.length > 63) {
        return "Label name cannot exceed 63 characters";
      } else {
        const regex = /[A-Z’/`~!#*$@%+=.,^&(){}[\]|;:”<>?\\]/g;
        if (name.match(regex)) {
          return "Only lowercase letters, numeric characters, underscores and dashes are allowed";
        } else {
          return null;
        }
      }
    }
  };

  const validateTagValue = (name) => {
    if (isAzure) {
      if (name.length > 256) {
        return "Tag value cannot exceed 256 characters";
      } else {
        return null;
      }
    } else {
      if (name.length > 63) {
        return "Label value cannot exceed 63 characters";
      } else {
        const regex = /[A-Z’/`~!#*$@%+=.,^&(){}[\]|;:”<>?\\]/g;
        if (name.match(regex)) {
          return "Only lowercase letters, numeric characters, underscores and dashes are allowed";
        } else {
          return null;
        }
      }
    }
  };

  const renderEditName = (params) => {
    const { error } = params;
    // return <NameEditInputCell {...params} />;
    return (
      <Tooltip
        open={!!error}
        title={error}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "red",
            },
          },
        }}
      >
        <GridEditInputCell {...params} />
      </Tooltip>
    );
  };

  let newTags = [];
  newTags = [...newTags, defaultAddTag];
  const [selectionModel, setSelectionModel] = useState([]);
  const [state, setState] = useState({
    editMode: false,
  });

  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Tag Name",
      width: 250,
      editable: true,
      preProcessEditCellProps(params) {
        const valid = validateTagName(params.props.value);
        return { ...params.props, error: valid };
      },
      renderEditCell: renderEditName,
    },
    {
      field: "value",
      headerName: "Tag Value",
      width: 250,
      editable: true,
      preProcessEditCellProps(params) {
        const valid = validateTagValue(params.props.value);
        return { ...params.props, error: valid };
      },
      renderEditCell: renderEditName,
    },
    {
      field: "modified",
      headerName: "Modified",
      width: 150,
      renderCell: (cellValues) => {
        if (cellValues.row.modified === true) {
          if (cellValues.row.remove === true) {
            return <Typography color="primary">Pending Remove</Typography>;
          } else {
            return <Typography color="primary">Pending Update</Typography>;
          }
        } else {
          return <Typography color="primary"></Typography>;
        }
      },
      description: "Toggle active subscription",
    },
    {
      field: "",
      headerName: "Delete",
      renderCell: (cellValues) => {
        return (
          <Tooltip title={"Delete Tag - Double Click"}>
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => {
                const selectedIDs = new Set(selectionModel);
                setTagSet((r) => r.filter((x) => !selectedIDs.has(x.id)));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  const modalStyles = {
    inputFields: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
      marginBottom: "15px",
      ".MuiFormControl-root": {
        marginBottom: "20px",
      },
    },
  };

  const processRowUpdate = (params, event) => {
    const updateTagSet = tagSet;
    updateTagSet[params.id - 1].name = params.name;
    updateTagSet[params.id - 1].value = params.value;
    updateTagSet[params.id - 1].modified = true;
    setTagSet(updateTagSet);
  };

  const handleProcessRowUpdateError = useCallback((error) => {}, []);

  const addTag = () => {
    defaultAddTag.id = tagSet.length + 1;
    newTags = [...tagSet, defaultAddTag];
    setTagSet(newTags);
  };

  const removeTag = () => {
    defaultRemoveTag.id = tagSet.length + 1;
    newTags = [...tagSet, defaultRemoveTag];
    setTagSet(newTags);
  };

  const getContent = () => (
    <>
      <br />
      <Box>
        <DataGrid
          rows={tagSet}
          rowCount={tagSet.length}
          columns={columns}
          loading={false}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
          onRowSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onCellEditStart={() => {
            setState((prevState) => {
              return { ...prevState, editMode: true };
            });
          }}
          onCellEditStop={() => {
            setState((prevState) => {
              return { ...prevState, editMode: false };
            });
          }}
        />
        <Box sx={modalStyles.buttons}>
          <div className="flex justify-center space-x-3">
            <Button
              variant="outlined"
              onClick={addTag}
              sx={hoverStyles}
            >
              Add Tag
            </Button>
            <Button
              variant="outlined"
              onClick={removeTag}
              sx={hoverStyles}
              disabled={!multiple}
            >
              Remove Tag
            </Button>

            {state.editMode ? (
              <Button
                variant="outlined"
                sx={{ marginRight: 2 }}
              >
                End Edit
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={processChanges}
                sx={hoverStyles}
              >
                Apply Changes
              </Button>
            )}
          </div>
        </Box>
      </Box>
    </>
  );

  return (
    <PopupModal
      open={open}
      onClose={onClose}
      title={title}
      subTitle={subTitle}
      content={getContent()}
    />
  );
};

export default BulkAdd;
