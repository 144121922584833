import React, { memo, useMemo, useEffect, useState } from "react";
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
  AzureMapPopup,
  IAzureDataSourceChildren,
  IAzureMapFeature,
  IAzureMapLayerType,
  IAzureMapOptions,
} from "react-azure-maps";
import {
  AuthenticationType,
  data,
  SymbolLayerOptions,
  MapMouseEvent,
  PopupOptions,
} from "azure-maps-control";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import uniqueId from "lodash";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { tokenValid } from "../helpers/tokenValid";

function clusterClicked(e: any) {
  window.open("/decomposition", "_blank");
}

const memoizedOptions: SymbolLayerOptions = {
  textOptions: {
    textField: ["get", "title"], //Specify the property name that contains the text you want to appear with the symbol.
    offset: [0, 1.2],
  },
};

const renderPoint = (markerData: any): IAzureMapFeature => {
  const result = (
    <>
      <AzureMapFeature
        id={uniqueId().toString()}
        key={uniqueId().toString()}
        type="Point"
        coordinate={markerData.coordinates}
        properties={{
          title: markerData.location,
          icon: "pin-blue",
          popUpProp: markerData.cost,
        }}
      />
    </>
  );
  return result;
};

const Map: React.FC = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [regionData, setRegionData] = useState({
    data:[],
    isLoading: true
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({...snackbar, open:false});
  };
  const dummyCoordinates = new data.Position(0, 0);
  const dummyOptions: PopupOptions = {
    position: dummyCoordinates,
    pixelOffset: [0, 0],
  };
  const [popupOptions, setPopupOptions] = useState({
    popOptions: dummyOptions,
    location: "",
    cost: 0,
    count: 0,
    visible: false,
  });
  const [popupProperties, setPopupProperties] = useState({});
  const [markers, setMarkers] = useState<any[]>([]);
  const [markersLayer] = useState<IAzureMapLayerType>("SymbolLayer");
  const [layerOptions, setLayerOptions] =
    useState<SymbolLayerOptions>(memoizedOptions);


  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }


  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const summaryGeoCostUrl =
      providerUrl +
      "/api/summary_geo_cost" +
      urlParameters;
    executeFetch("GET", summaryGeoCostUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          let newMarkers = [] as any[];
          response.data.forEach(
            (item: {
              id: any;
              ResourceCount: any;
              Location: any;
              EstimatedCost: any;
              Longitude: number;
              Latitude: number;
            }) => {
              const newPoint = new data.Position(
                item.Longitude,
                item.Latitude
              );
              const markerData = {
                coordinates: newPoint,
                location: item.Location,
                resourceCount: item.ResourceCount,
                cost: item.EstimatedCost,
                id: item.id,
              };
              newMarkers = [...newMarkers, markerData];
            }
          );
          setMarkers(newMarkers);
          localStorage.setItem("summary_region", JSON.stringify(newMarkers));
          setRegionData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);


  const option: IAzureMapOptions = useMemo(() => {
    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: rest.mapKey,
      },
      center: [-100.01, 45.01],
      zoom: 4,
      view: "Auto",
    };
  }, []);

  const memoizedMarkerRender: IAzureDataSourceChildren = useMemo(
    (): any => markers.map((marker) => renderPoint(marker)),
    [markers]
  );

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto", pl: "10px" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <AzureMapsProvider>
          <div style={styles.map}>
            <AzureMap options={option}>
              <AzureMapDataSourceProvider
                events={{
                  dataadded: (e: any) => {},
                }}
                //id={'markers AzureMapDataSourceProvider'}
                id={uniqueId().toString()}
                options={{ cluster: false }}
              >
                <AzureMapLayerProvider
                  //id={'markers AzureMapLayerProvider'}
                  id={uniqueId().toString()}
                  options={layerOptions}
                  events={{
                    click: clusterClicked,
                    dbclick: clusterClicked,
                    mousemove: (e: MapMouseEvent) => {
                      if (e.shapes && e.shapes.length > 0) {
                        const prop: any = e.shapes[0];
                        const coordinates = new data.Position(
                          prop.data.geometry.coordinates[0],
                          prop.data.geometry.coordinates[1]
                        );
                        const options: PopupOptions = {
                          position: coordinates,
                          pixelOffset: [0, -18],
                        };
                        const markersData =
                          localStorage.getItem("summary_region");
                        const markers = JSON.parse(markersData as string);

                        markers.forEach(
                          (element: {
                            coordinates: number[];
                            location: any;
                            cost: any;
                            resourceCount: any;
                          }) => {
                            if (element.coordinates[0] === coordinates[0]) {
                              const popupOptionsData = {
                                popOptions: options,
                                location: element.location,
                                cost: element.cost,
                                count: element.resourceCount,
                                visible: true,
                              };
                              setPopupOptions(popupOptionsData);
                              localStorage.setItem(
                                "region_select",
                                JSON.stringify(popupOptionsData)
                              );
                            }
                          }
                        );
                        if (prop.data.properties)
                          setPopupProperties({
                            ...prop.data.properties,
                            dumpProp: "My Popup",
                          });
                      }
                    },
                  }}
                  type={markersLayer}
                />
                {memoizedMarkerRender}
              </AzureMapDataSourceProvider>
              <AzureMapPopup
                isVisible={popupOptions.visible}
                options={popupOptions.popOptions}
                popupContent={
                  <div style={{ padding: "8px 16px" }}>
                    <h1>{popupOptions.cost}</h1>
                    <h2>{popupOptions.count} Resources</h2>
                  </div>
                }
              />
            </AzureMap>
          </div>
        </AzureMapsProvider>
        /
      </Box>
    </>
  );
};

const styles = {
  map: {
    height: 1000,
  },
};

export default memo(Map);
