import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: '#29707A', //kyndryl green
    },
    secondary: {
      main: '#19857b', 
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export const hoverStyles = {
  "&:hover": {
    color: '#fff',
    backgroundColor: 'primary.main'
  },
}