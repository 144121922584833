import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Dialog, Box, DialogTitle, Typography, Button } from "@mui/material";
import { useAuth } from "../../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../../styles/theme";

export const SessionDialog = () => {
  const { instance } = useMsal();
  const { ...rest } = useAuth();
  console.log(rest);
  
  const handleRefresh = () => {
    window.location.reload();
  }
  const signOutClickHandler = (instance) => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      mainWindowRedirectUri: "/",
      postLogoutRedirectUri: "/",
    };
    instance.logoutPopup(logoutRequest);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center"
      >
        <Dialog open={rest.refreshToken}>
          <DialogTitle>{"Login session expired."}</DialogTitle>
          <Box
            display="flex"
            justifyContent="center"
          >
            <div className="p-5">

            <div className="flex-col space-y-5">
              <div className="flex justify-center">
                <Typography>Please renew your session or sign out.</Typography>
              </div>
              <div className="flex space-x-3">
                <Button
                  variant="outlined"
                  sx={hoverStyles}
                  onClick={() => handleRefresh()}
                >
                  Renew Session
                </Button>
                <Button
                  variant="outlined"
                  sx={hoverStyles}
                  onClick={() => signOutClickHandler(instance) }
                >
                  Sign out
                </Button>
              </div>
            </div>
            </div>
          </Box>
          <br />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};
