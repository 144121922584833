import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../authContext";

export const ProtectedRoute = ({ ...props }) => {
  const { ...rest } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(true);

  const onLoad = () => {
    if (typeof props.roles === "undefined") {
      setIsAuthorized(true);
    } else {
      let intersection = props.roles.filter((role) =>
        rest.roles.includes(role)
      );
      if (intersection.length > 0) {
        setIsAuthorized(true);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [rest]);

  return (
    <React.Fragment>
      {isAuthorized ? <>{props.children}</> : <Navigate to="/" />}
    </React.Fragment>
  );
};
