import {
  AuthenticatedTemplate,
} from "@azure/msal-react";
import OrganizationDataTable from "../gcp-components/GCPOrganizationData";

export const OrganizationsPage = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <OrganizationDataTable />
      </AuthenticatedTemplate>
    </>
  );
};
