import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  { field: "Location", headerName: "Location", width: 150 },
  { field: "EstimatedCost", headerName: "Estimated Cost", width: 150 },
  { field: "ResourceCount", headerName: "Resource Count", width: 150 },
];
const userTableStyles = {
  height: "1000px",
};

const RegionDataTable = ({ onError }) => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [regionData, setRegionData] = useState({
    data:[],
    isLoading: true
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open:false});
  };

  
  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const summaryGeoCostUrl =
      providerUrl +
      "/api/summary_geo_cost" +
      urlParameters;
    executeFetch("GET", summaryGeoCostUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          setRegionData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);
  
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto", pl: "10px" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <DataGrid
          rows={regionData.data}
          rowCount={regionData.data.length}
          columns={columns}
          loading={regionData.isLoading}
          sx={userTableStyles}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default RegionDataTable;
