import GCPProjectCostReportDataTable from "../gcp-components/GCPProjectCostReportData";
import GCPRegionCostReportDataTable from "../gcp-components/GCPRegionCostReportData";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../styles/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ReportingGCPPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthenticatedTemplate>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                paddingTop: "25px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Project Cost"
                  {...a11yProps(0)}
                />
                <Tab
                  label="Region Cost"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={value}
              index={0}
            >
              <GCPProjectCostReportDataTable />
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
            >
              <GCPRegionCostReportDataTable />
            </TabPanel>
          </Box>
        </AuthenticatedTemplate>
      </ThemeProvider>
    </>
  );
}
