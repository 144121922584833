import React, { useState, useContext, createContext } from "react";
import useLocalStorage from "./hooks/useLocalStorage";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = (props) => {
  const [username, setUsername] = useState(null);
  const [name, setName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTagWriter, setIsTagWriter] = useState(false);
  const [roles, setRoles] = useState([]);
  const [token, setToken] = useState(null);
  const [mapKey, setMapKey] = useState(null);
  const [provider, setProvider] = useLocalStorage('provider', "");
  const [subscription, setSubscription] = useLocalStorage('subscription', "");
  const [customer, setCustomer] = useLocalStorage('customer', "");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const value = {
    username,
    setUsername,
    name,
    setName,
    isAdmin,
    setIsAdmin,
    isTagWriter,
    setIsTagWriter,
    roles,
    setRoles,
    token,
    setToken,
    refreshToken,
    setRefreshToken,
    mapKey,
    setMapKey,
    isAuthenticated,
    setIsAuthenticated,
    provider,
    setProvider,
    subscription,
    setSubscription,
    customer,
    setCustomer
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
