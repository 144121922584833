import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";

export const SubscriptionName = () => {
  const [subscription, setSubscription] = useState();
  const [provider, setProvider] = useState();
  const [customer, setCustomer] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    const subscriptionData = JSON.parse(localStorage.getItem("subscription"));
    if (subscriptionData && subscriptionData !== null) {
      setSubscription(subscriptionData);
    }

    const providerData = JSON.parse(localStorage.getItem("provider"));
    if (providerData && providerData !== null) {
      setProvider(providerData);
    }

    const customerData = JSON.parse(localStorage.getItem("customer"));
    if (customerData && customerData !== null) {
      setCustomer(customerData);
    }

    let msg = "Not Selected";
    if (providerData && providerData != null) {
      if (providerData.provider === "Azure") {
        msg = providerData.friendly_name + " " + providerData.provider;

        if (subscriptionData) {
          msg = msg + " Subscription: " + subscriptionData.friendlyName;
        } else {
          msg = msg + " Subscription: No subscription selected";
        }
      } else {
        msg = providerData.friendly_name + " " + providerData.provider;

        if (customerData) {
          msg =
            providerData.friendly_name +
            " " +
            providerData.provider +
            " Customer: " +
            customerData.name;
        } else {
          msg =
            providerData.friendly_name +
            " " +
            providerData.provider +
            " Customer: No customer selected";
        }
      }
    }

    setMessage(msg);
  }, []);

  return (
    <Typography style={{ marginRight: 10 }} variant="h6">
      Current Provider:
      {message}
    </Typography>
  );
};
