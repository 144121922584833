import React, { useEffect } from "react";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Avatar from "react-avatar";
import { Typography } from "@mui/material";
import { LogoutButton } from "./LogoutButton";
import { useAuth } from "../authContext";
export const UserPopupState = () => {
  const { ...rest } = useAuth();

  useEffect(() => {
  }, [rest.username])

  return (
    <PopupState
      variant="popover"
      popupId="userid-popup-popover"
    >
      {(popupState) => (
        <>
          <Avatar
            size="50"
            color="#29707A"
            name={rest.name}
            variant="contained"
            {...bindTrigger(popupState)}
          >
            Open Popover
          </Avatar>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            slotProps={{
              root: {
                sx: {
                  overflowY: "scroll",
                  bottom: -2, // Added
                },
              },
            }}
          >
            <div className="flex-col p-4">

              <Typography
                variant="h6"
                color="primary"
              >
                {rest.name}
              </Typography>
              <Typography

              >{rest.username}</Typography>
              <div className="flex pb-3">
                <Typography sx={{ paddingRight: 1 }}>Roles: </Typography>
                <div>
                  {rest.roles.map((element) => (
                    <Typography>{element}</Typography>
                  ))}
                </div>
              </div>
              <LogoutButton />
              <br />
            </div>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
