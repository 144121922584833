import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import { Box, Paper, IconButton, Collapse, Button } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, Check } from "@mui/icons-material";
import PopupModal from "../PopupModal/PopupModal";
import uniqueId from "lodash";
import { useAuth } from "../../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../../styles/theme";

const TagConfirm = ({
  open,
  onClose,
  updatedResources,
  applyChanges,
  title,
  subTitle,
  expand,
}) => {
  const { ...restModal } = useAuth();
  let isAzure = true;
  if (restModal.provider !== "") {
    if (restModal.provider.provider === "gcp") {
      isAzure = false;
    }
  }

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(expand);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          {isAzure ? (
            <TableCell component="th" scope="row">
              {row.resName}
            </TableCell>
          ) : (
            <TableCell component="th" scope="row">
              {row.asset}
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tag Name</TableCell>
                      <TableCell>Tag Value</TableCell>
                      <TableCell>Modified</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{ExpandedTags(row)}</TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  function ExpandedTags(row) {
    return (
      <>
        {row.newTags.map((tag) => (
          <TableRow key={uniqueId()}>
            <TableCell component="th" scope="row">
              {tag.name}
            </TableCell>
            <TableCell>{tag.value}</TableCell>
            <TableCell>
              {tag.modified ? <Check style={{ color: "green" }}></Check> : null}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  function SimpleTable() {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Resource Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedResources.map((row) => (
              <Row key={uniqueId()} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const getContent = () => (
    <>
      <br />
      <Box>
        <SimpleTable />
        <Button
          variant="outlined"
          onClick={applyChanges}
          sx={hoverStyles}
        >
          Confirm Changes
        </Button>
      </Box>
    </>
  );

  return (
    <PopupModal
      open={open}
      onClose={onClose}
      title={title}
      subTitle={subTitle}
      content={getContent()}
    />
  );
};

export default TagConfirm;
