import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import PopupModal from "../PopupModal/PopupModal";

const userTableStyles = {
  height: "800px",
};

const TagApply = ({ open, onClose, tagSet }) => {
  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Tag Name",
      width: 300,
      renderCell: (cellValues) => {
        if (cellValues.row.modified === true) {
          return <Typography color="error">{cellValues.row.name}</Typography>;
        } else {
          return <Typography>{cellValues.row.name}</Typography>;
        }
      },
      description: "Toggle active subscription",
    },
    {
      field: "value",
      headerName: "Tag Value",
      width: 300,
      renderCell: (cellValues) => {
        if (cellValues.row.modified === true) {
          return <Typography color="error">{cellValues.row.value}</Typography>;
        } else {
          return <Typography>{cellValues.row.value}</Typography>;
        }
      },
      description: "Toggle active subscription",
    },
  ];
  const modalStyles = {
    inputFields: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
      marginBottom: "15px",
      ".MuiFormControl-root": {
        marginBottom: "20px",
      },
    },
  };

  const getContent = () => (
    <>
      <br />
      <Box>
        <DataGrid
          rows={tagSet}
          rowCount={tagSet.length}
          columns={columns}
          loading={false}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
        />
        <Box sx={modalStyles.buttons}></Box>
      </Box>
    </>
  );

  return (
    <PopupModal
      open={open}
      onClose={onClose}
      title="Bulk Resource Tagging Confirmation"
      subTitle="Please confirm the changes below in red and confirm"
      content={getContent()}
    />
  );
};

export default TagApply;
