import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const ProviderDataTable = () => {
  const { ...rest } = useAuth();
  const providerUrl = process.env.REACT_APP_BACKEND_URI + "/api/providers";
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [providerData, setProviderData] = useState({
    data: [],
    isLoading: true,
  });

  const handleClick = (event, cellValues) => {
    if (cellValues.row.provider.provider !== rest.provider.provider) {
      localStorage.removeItem("customer");
      localStorage.removeItem("subscription");
    }
    localStorage.setItem("provider", JSON.stringify(cellValues.row));
    rest.setProvider(cellValues.row);
    window.location = "/subscriptions";
  };

  const columns = [
    {
      field: "friendly_name",
      headerName: "Friendly Name",
      width: 200,
      editable: false,
    },
    {
      field: " ",
      renderCell: (cellValues) => {
        if (cellValues.row.selected === "1") {
          return (
            <ThemeProvider theme={theme}>
              <Tooltip title={cellValues.row.organization_tenant}>
                <Typography
                  variant="h6"
                  color="primary"
                >
                  Active
                </Typography>
              </Tooltip>
            </ThemeProvider>
          );
        } else {
          return (
            <ThemeProvider theme={theme}>
              <Tooltip title={cellValues.row.organization_tenant}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={hoverStyles}
                  onClick={(event) => {
                    handleClick(event, cellValues);
                  }}
                >
                  Switch
                </Button>
              </Tooltip>
            </ThemeProvider>
          );
        }
      },
      description: "Toggle active provider",
    },
    {
      field: "organization_tenant",
      headerName: "Organization/Tenant",
      width: 500,
      editable: false,
    },
    { field: "url", headerName: "API URL", width: 500, editable: false },
    { field: "provider", headerName: "Provider", width: 250, editable: false },
  ];

  const userTableStyles = {
    height: "1000px",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  useEffect(() => {
    if (!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    executeFetch("GET", providerUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        if (!response.isLoading) {
          let data = response.data;
          if (data.length === 0) {
            rest.setProvider("");
          } else {
            data.forEach((element) => {
              element.selected = "0";
              if (rest.provider && rest.provider !== "") {
                if (element.friendly_name === rest.provider.friendly_name) {
                  element.selected = "1";
                }
              }
            });
            if (!rest.provider || rest.provider === "") {
              data.every((element) => {
                element.selected = "1";
                rest.setProvider(element);
                return false;
              });
            }
          }

          setProviderData(response);
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <DataGrid
          rows={providerData.data}
          rowCount={providerData.data.length}
          columns={columns}
          loading={providerData.isLoading}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default ProviderDataTable;
