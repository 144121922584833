import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CustomerModal from "./CustomerModal";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const userTableStyles = {
  height: "1000px",
};

const AdminGCPCustomerDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customersData, setCustomersData] = useState({
    data: [],
    isLoading: true,
  });
  const [customerData, setCustomerData] = useState({
    id: 0,
    customer_id: "",
    name: "",
    is_active: "",
    created_at: new Date().getDate(),
    updated_at: new Date().getDate(),
  });
  const [refreshCustomerData, setRefreshCustomerData] = useState(true);
  const [updateCustomerData, setUpdateCustomerData] = useState({
    url: "",
    method: "",
    payload: null,
    update: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const saveCustomerData = (data) => {
    console.log(data);
    let customerCrudUrl = "";
    let method = "";
    if (data.id === 0) {
      customerCrudUrl = providerUrl + "/customers";
      method = "POST";
    } else {
      customerCrudUrl = providerUrl + "/customers/" + data.customer_id;

      method = "PUT";
    }
    setUpdateCustomerData({
      url: customerCrudUrl,
      method: method,
      payload: data,
      update: true,
    });
  };

  let providerUrl = "";
  if (rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (refreshCustomerData) {
      const customersUrl = providerUrl + "/customers";
      executeFetch("GET", customersUrl, rest.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } else {
            let data = response.data;
            //if (rest.customer !== "") {
              let index = 1;
              data.forEach((element) => {
                element.id = index;
                index = index + 1;
              });
            //} else {
            //  data.every((element) => {
            //    element.active = "1";
            //    return false;
            //  });
            //}
            setCustomersData({ data: data, isLoading: false });
            setRefreshCustomerData(false);
          }
        }
      });
    }
  }, [executeFetch, refreshCustomerData, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (updateCustomerData.update) {
      executeFetch(
        updateCustomerData.method,
        updateCustomerData.url,
        rest.token,
        updateCustomerData.payload
      ).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          }
        }
      });
      setCustomersData({
        data: [],
        isLoading: true,
      });
      setUpdateCustomerData({
        method: "",
        url: "",
        payload: null,
        update: false,
      });
      setRefreshCustomerData(true);
    }
  }, [executeFetch, updateCustomerData, rest.refreshToken]);

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 20,
      renderCell: (cellValues) => {
        return (
          <ThemeProvider theme={theme}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={(event) => {
                  handleRowEditClick(event, cellValues);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 200,
      editable: false,
    },
    { field: "name", headerName: "Name", width: 300, editable: false },
    { field: "is_active", headerName: "Active", width: 300, editable: false },
    { field: "created_at", hide: true },
    { field: "updated_at", hide: true },
    {
      field: " ",
      headerName: "Delete",
      width: 60,
      Tooltip: "Delete",
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              handleDeleteClick(event, cellValues);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      description: "Delete Customer",
    },
  ];

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this customer?"
    );
    if (isConfirmed) {
      const customerCrudUrl = providerUrl + "customers/" + cellValues.row.id;
      setUpdateCustomerData({
        url: customerCrudUrl,
        method: "DELETE",
        payload: null,
        update: true,
      });
    }
  };

  const addApplication = () => {
    setCustomerData({
      id: 0,
      customer_id: "",
      name: "",
      is_active: "0",
      created_at: new Date().getDate(),
      updated_at: new Date().getDate(),
    });
    setShowCustomerModal(true);
  };

  const handleRowEditClick = (event, cellValues) => {
    setCustomerData({
      id: cellValues.row.id,
      customer_id: cellValues.row.customer_id,
      name: cellValues.row.name,
      is_active: cellValues.row.is_active,
      created_at: cellValues.row.created_at,
      updated_at: cellValues.row.updated_at,
    });
    setShowCustomerModal(true);
  };

  return (
    <>
      <div>
        <ThemeProvider theme={theme}>
          <Button
            variant="outlined"
            sx={hoverStyles}
            color="primary"
            onClick={addApplication}
          >
            Add Customer
          </Button>
          <Box
            component="main"
            sx={{ flexGrow: 1, mx: "auto" }}
          >
            <BasicSnackbar
              open={snackbar.open}
              severity={snackbar.severity}
              message={snackbar.message}
              onClose={handleClose}
              vertical="top"
              horizontal="right"
            />
            <DataGrid
              rows={customersData.data}
              rowCount={customersData.data.length}
              columns={columns}
              loading={customersData.isLoading}
              sx={userTableStyles}
              disableColumnSelector={true}
              disableMultipleSelection={true}
              hideFooterPagination
              columnVisibilityModel={{
                id: false,
              }}
            />

            <CustomerModal
              open={showCustomerModal}
              onClose={() => setShowCustomerModal(false)}
              saveData={saveCustomerData}
              customerData={customerData}
            />
          </Box>
        </ThemeProvider>
      </div>
    </>
  );
};

export default AdminGCPCustomerDataTable;
