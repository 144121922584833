import React from "react";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import Globe from "@mui/icons-material/PublicOutlined";
import Tagging from "@mui/icons-material/More";
import Decomp from "@mui/icons-material/BlurLinearOutlined";
import Dashboard from "@mui/icons-material/Dashboard";
import Analytics from "@mui/icons-material/Analytics";
import Subscriptions from "@mui/icons-material/Subscriptions";

export const MenuAzure = () => {
  return (
    <React.Fragment>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/subscriptions">
          <ListItemIcon>
            <Tooltip title="Subscriptions">
              <Subscriptions />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Subscriptions" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/region">
          <ListItemIcon>
            <Tooltip title="Geography">
              <Globe />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Geography" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/dashboard">
          <ListItemIcon>
            <Tooltip title="Dashboard">
              <Dashboard />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/tagging">
          <ListItemIcon>
            <Tooltip title="Tagging">
              <Tagging />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Tagging" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/decomposition">
          <ListItemIcon>
            <Tooltip title="Decomposition">
              <Decomp />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Decomposition" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/reporting">
          <ListItemIcon>
            <Tooltip title="Reporting">
              <Analytics />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Reporting" />
        </ListItemButton>
      </div>
    </React.Fragment>
  );
};
