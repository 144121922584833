import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MonthtoDateCost from "./MonthtoDateCost";
import YeartoDateCost from "./YeartoDateCost";
import MonthtoDateForecast from "./MonthtoDateForecast";
import MonthlyCostDelta from "./MonthlyCostDelta";
import CurrentAdvisorData from "./CurrentAdvisorData";
import CurrentTagData from "./CurrentTagData";

import TagResourceCountsTable from "../azure-components/ReportTagResourceCounts";
import OrphanDisk from "./OrphanDisk";
import OrphanVM from "./OrphanVM";
import OrphanNic from "./OrphanNic";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#29707A" ? "#29707A" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function RowOne() {
  return (
    <React.Fragment>
      <Grid item xs={2} align="center">
        {/* <Item style={{ color: "white", backgroundColor: "#29707A" }}>
          {" "} 
          Month Cost to Date
        </Item>*/}
        <MonthtoDateCost />
      </Grid>
      <Grid item xs={2} align="center">
        <MonthtoDateForecast />
      </Grid>
      <Grid item xs={2} align="center">
        <MonthlyCostDelta />
      </Grid>
      <Grid item xs={2} align="center">
        <OrphanVM />
      </Grid>
      <Grid item xs={2} align="center">
        <OrphanNic />
      </Grid>
      <Grid item xs={2} align="center">
        <OrphanDisk />
      </Grid>
    </React.Fragment>
  );
}

function RowTwo() {
  return (
    <React.Fragment>
      <Grid item xs={12} align="center">
        <CurrentTagData />
      </Grid>
    </React.Fragment>
  );
}

function RowThree() {
  return (
    <React.Fragment>
      <Grid item xs={6} align="center">
        <YeartoDateCost />
      </Grid>
      <Grid item xs={6} align="center">
        <CurrentAdvisorData />
      </Grid>
    </React.Fragment>
  );
}

export default function NestedGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid container item spacing={1}>
          <RowOne />
        </Grid>
        <Grid container item spacing={1}>
          <RowTwo />
        </Grid>
        <Grid container item spacing={1}>
          <RowThree />
        </Grid>
      </Grid>
    </Box>
  );
}
