import { Navbar, Typography } from "@material-tailwind/react";
// Logo resources
import kyndrylLogo from "../assets/kyndrylLogo";
import { UserPopupState } from "./UserPopupState";
import { useAuth } from "../authContext";

export const StickyNavbar = () => {
  const { ...rest } = useAuth();
  return (
    <Navbar className="ml-0 pt-2 sticky flex justify-between pl-5 pr-5">
      <img
        className="w-50 object-fit"
        alt="Kyndryl Logo"
        src={kyndrylLogo}
      />
      <Typography className="text-3xl text-black bg-white mr-4 cursor-pointer py-1.5 font-medium">
        Tag and Inventory Optimization
      </Typography>
      <div className="flex-col">
        <Typography className="text-lg text-black bg-white mr-4 cursor-pointer py-1.5 font-medium">
          Provider: {rest.provider ? rest.provider.provider : "None Selected"}
        </Typography>
        {rest.provider.provider === "Azure" ? (
          <Typography className="text-lg text-black bg-white mr-4 cursor-pointer py-1.5 font-medium">
            Subscription: {rest.subscription ? rest.subscription.friendlyName : "None Selected"}
          </Typography>
        ) : null}
        {rest.provider.provider === "GCP" ? (
          <Typography className="text-lg text-black bg-white mr-4 cursor-pointer py-1.5 font-medium">
            Customer: {rest.customer ? rest.customer.name : "None Selected"}
          </Typography>
        ) : null}
      </div>
      <UserPopupState />
    </Navbar>
  );
};
