import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import BasicSnackbar from "../components/BasicSnackbar";
import { renderCellExpand } from "../components/RenderCellExpand";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  { field: "name", headerName: "Name", hide: true },
  { field: "subscription", headerName: "Subscription", hide: true },
  { field: "type", hide: true },
  {
    field: "resId",
    headerName: "Resource",
    width: 500,
    renderCell: renderCellExpand,
  },
  {
    field: "friendlyName",
    headerName: "Friendly Name",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "date",
    headerName: "Date",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "severity",
    headerName: "Severity",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    field: "problem",
    headerName: "Problem",
    width: 450,
    renderCell: renderCellExpand,
  },
  {
    field: "solution",
    headerName: "Solution",
    width: 450,
    renderCell: renderCellExpand,
  },
  {
    field: "impactField",
    headerName: "Impact Field",
    width: 250,
    renderCell: renderCellExpand,
  },
  { field: "impactValue", hide: true },
];
const userTableStyles = {
  height: "1000px",
};

const TenantAdvisorTable = ({ isWriter }) => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [advisoryData, setAdvisoryData] = useState({
    data: [],
    isLoading: true
  });

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters = urlParameters + "tenant=" + rest.subscription.tenant;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: "flex" }}>
        <GridToolbarQuickFilter sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
        <GridToolbarExport
          variant="contained"
          sx={{ mr: 2, pt: 0.8, pb: 0.8 }}
        />
        <Typography variant="h6">Azure Advisory Data</Typography>
        <GridToolbarDensitySelector sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
      </GridToolbarContainer>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open: false});
  };

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const advisorUrl =
      providerUrl +
      "/api/report_tenant_advisor?" +
      urlParameters;

    console.log(advisorUrl)  
    executeFetch("GET", advisorUrl).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setAdvisoryData({data: response.data, isLoading: false});
        }
      }
    });  
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Typography></Typography>
        <DataGrid
          rows={advisoryData.data}
          rowCount={advisoryData.data.length}
          columns={columns}
          loading={advisoryData.isLoading}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Box>
    </>
  );
};

export default TenantAdvisorTable;
