import React from "react";
import ReactDOM from "react-dom/client";
import { GlobalStyles } from "twin.macro";
import { msalConfig } from "./authConfig";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { ThemeProvider } from "@material-tailwind/react";
import App from "./App";
import "./index.css";

export const msalInstance = new PublicClientApplication(msalConfig);

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <ThemeProvider>
      <App msalInstance={msalInstance} />
    </ThemeProvider>
  </React.StrictMode>
);
