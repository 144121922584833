import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { renderCellExpand } from "../components/RenderCellExpand";
import { v4 } from "uuid";
import SubscriptionModal from "./SubscriptionModal";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const userTableStyles = {
  height: "1000px",
};

const AdminSubscriptionDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionsData, setSubscriptionsData] = useState({
    data: [],
    isLoading: true,
  });
  const [subscriptionData, setSubscriptionData] = useState({
    id: -1,
    display_name: "",
    subscription_name: "",
    subscription_id: "",
    tenant_id: "",
    client_id: "",
    client_secret: "",
    active: "",
  });
  const [refreshSubscriptionData, setRefreshSubscriptionData] = useState(true);
  const [updateSubscriptionData, setUpdateSubscriptionData] = useState({
    url: "",
    method: "",
    payload: null,
    update: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const saveSubscriptionData = (data) => {
    console.log(data);
    let subscriptionCrudUrl = "";
    let method = "";
    let update = false;

    if( data.id >= 0 ) {
    
      if (data.id === 0) {
        subscriptionCrudUrl = providerUrl + "/api/insert_subscription";
        method = "POST";
      } else {
        subscriptionCrudUrl =
          providerUrl + "/api/update_subscription?subscription=" + data.id;
        method = "PUT";
      }
      setUpdateSubscriptionData({
        url: subscriptionCrudUrl,
        method: method,
        payload: data,
        update: true,
      });
    }
  };

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (refreshSubscriptionData) {
      const subscriptionsUrl =
        providerUrl + "/api/subscriptions?cachebreaker=" + v4();
      executeFetch("GET", subscriptionsUrl, rest.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } else {
            let data = response.data;
            if (rest.subscription !== "") {
              data.forEach((element) => {
                if (element.subscription === rest.subscription.subscription) {
                  element.active = "1";
                }

                element.isDirty = 0;
              });
            } else {
              data.every((element) => {
                element.active = "1";
                return false;
              });
            }
            setSubscriptionsData({ data: data, isLoading: false });
            setRefreshSubscriptionData(false);
          }
        }
      });
    }
  }, [executeFetch, refreshSubscriptionData, rest.refreshToken]);

  useEffect(() => {
    if (updateSubscriptionData.update) {
      executeFetch(
        updateSubscriptionData.method,
        updateSubscriptionData.url,
        rest.token,
        updateSubscriptionData.payload
      ).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          }
        }
      });
      setSubscriptionsData({
        data: [],
        isLoading: true,
      });
      setUpdateSubscriptionData({
        method: "",
        url: "",
        payload: null,
        update: false,
      });
      setRefreshSubscriptionData(true);
    }
  }, [executeFetch, updateSubscriptionData]);

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 20,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="edit"
            onClick={(event) => {
              handleRowEditClick(event, cellValues);
            }}
          >
            <EditIcon />
          </IconButton>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: "friendlyName",
      headerName: "Display Name",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "name",
      headerName: "Subscription Name",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "subscription",
      headerName: "Subscription ID",
      width: 325,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "tenant",
      headerName: "Tenant ID",
      width: 325,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "clientID",
      headerName: "Client ID",
      width: 300,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "clientSecret",
      headerName: "Client Secret",
      width: 300,
      hide: true,
      renderCell: renderCellExpand,
    },
    { field: "active", hide: true },
    {
      field: " ",
      headerName: "Delete",
      width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              handleDeleteClick(event, cellValues);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      description: "Delete subscription",
    },
  ];

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this subscription?"
    );
    if (isConfirmed) {
      const subscriptionCrudUrl =
        providerUrl +
        "/api/delete_subscription?subscription=" +
        cellValues.row.id;
      setUpdateSubscriptionData({
        url: subscriptionCrudUrl,
        method: "DELETE",
        payload: null,
        update: true,
      });
    }
  };

  const addApplication = () => {
    setSubscriptionData({
      id: 0,
      display_name: "",
      subscription_name: "",
      subscription_id: "",
      tenant_id: "",
      client_id: "",
      client_secret: "",
      active: "0",
    });
    setShowSubscriptionModal(true);
  };

  const handleRowEditClick = (event, cellValues) => {
    setSubscriptionData({
      id: cellValues.row.id,
      display_name: cellValues.row.friendlyName,
      subscription_name: cellValues.row.name,
      subscription_id: cellValues.row.subscription,
      tenant_id: cellValues.row.tenant,
      client_id: cellValues.row.clientID,
      client_secret: cellValues.row.clientSecret,
      active: cellValues.row.active,
    });
    setShowSubscriptionModal(true);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          sx={hoverStyles}
          color="primary"
          onClick={addApplication}
        >
          Add Subscription
        </Button>
        <Box
          component="main"
          sx={{ flexGrow: 1, mx: "auto" }}
        >
          <BasicSnackbar
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={handleClose}
            vertical="top"
            horizontal="right"
          />
          <DataGrid
            rows={subscriptionsData.data}
            rowCount={subscriptionsData.data.length}
            columns={columns}
            loading={subscriptionsData.isLoading}
            sx={userTableStyles}
            disableColumnSelector={true}
            disableMultipleSelection={true}
            hideFooterPagination
            columnVisibilityModel={{
              clientSecret: false,
            }}
          />
          <SubscriptionModal
            open={showSubscriptionModal}
            onClose={() => setShowSubscriptionModal(false)}
            saveData={saveSubscriptionData}
            subscriptionData={subscriptionData}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdminSubscriptionDataTable;
