import { useEffect, useState, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../authContext";

export const RefreshToken = () => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { ...rest } = useAuth();

  useEffect(() => {
    if (rest.token) {
      const tokenExpiryTime = jwtDecode(rest.token).exp * 1000;
      const fourMinPriorExpiryTime = tokenExpiryTime - 4 * 60000;
      const currentTime = Date.now();
      const intervalTime = fourMinPriorExpiryTime - currentTime;
      const interval = setInterval(() => {
        if (tokenExpiryTime < fourMinPriorExpiryTime) {
          console.log("Token about to expire: ");
          forceUpdate();
        }
      }, intervalTime);
      return () => clearInterval(interval);
    }
  });
};
