import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { AdminPage } from "../pages/Admin";
import { HomePage } from "../pages/Home";
import { SubscriptionPage } from "../pages/Subscriptions";
import { OrganizationsPage } from "../pages/Organizations";
import { TagPage } from "../pages/Tagging";
import { RegPage } from "../pages/Region";
import { ReportingPage } from "../pages/Reporting";
import { ReportingGCPPage } from "../pages/ReportingGCP";
import { TenantPage } from "../pages/TenantResources";
import { DecompPage } from "../pages/Decomposition";
import { DashboardPage } from "../pages/CostManagement";
import { TasksPage } from "../pages/Tasks";
import { appRoles } from "../authConfig";
import { useAuth } from "../authContext";

export const AppRoutes = () => {
  const { ...rest } = useAuth();

  useEffect(() => {}, [rest]);

  return (
    <Routes>
      <Route
        path="/"
        element={<HomePage />}
      />
      <Route
        path="/subscriptions"
        element={
          <ProtectedRoute>
            <SubscriptionPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/region"
        element={
          <ProtectedRoute>
            <RegPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tagging"
        element={
          <ProtectedRoute>
            <TagPage
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/decomposition"
        element={
          <ProtectedRoute>
            <DecompPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reporting"
        element={
          <ProtectedRoute>
            <ReportingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reportingGCP"
        element={
          <ProtectedRoute>
            <ReportingGCPPage />
          </ProtectedRoute>
        }
      
      />
      <Route
        path="/tenantResources"
        element={
          <ProtectedRoute roles={[appRoles.Admins]}>
            <TenantPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute roles={[appRoles.Admins]}>
            <AdminPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tasks"
        element={
          <ProtectedRoute>
            <TasksPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/organizations"
        element={
          <ProtectedRoute>
            <OrganizationsPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
