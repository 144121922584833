import React, { useEffect, useState } from "react";
import BasicSnackbar from "../components/BasicSnackbar";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { tokenValid } from "../helpers/tokenValid";

import { PieChart } from '@mui/x-charts/PieChart';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/system/Unstable_Grid";

import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';

const CurrentAdvisorData = ({ onError }) => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [advisoryData, setAdvisoryData] = useState({
    data: [],
    isLoading: true,
  });


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const AdvisoryUrl = providerUrl + "/api/advisory_type" + urlParameters;
    executeFetch("GET", AdvisoryUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          setAdvisoryData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch]);
  
  return (
    <>
      <BasicSnackbar
        open={snackbar.open}
        severity={snackbar.severity}
        message={snackbar.message}
        onClose={handleClose}
        vertical="top"
        horizontal="right"
      />
      <Grid
          container
          spacing={0}></Grid>
        <Grid xs={6} >
            <Card>

            <CardHeader
      
              title="Advisor Categories"
              // subheader="Current Month"
              >
      
            </CardHeader>
          <CardContent>
        
            {advisoryData.isLoading ? null :
              <PieChart margin={{ top: 10, bottom: 10, left: 10, right:250 }}
              colors={cheerfulFiestaPalette}
              series={[
                {
                  data: advisoryData.data,
                }
              ]}
              slotProps={{
                legend: {
                  direction: 'column',
                  markGap: 5,
                  itemGap: 5,
                  labelStyle: {
                    fontSize: 11,
                  },
                }
              }}
              width={500}
              height={200}
                
              />
            }
          </CardContent>
        </Card>
        </Grid>
    </>
  );
};

export default CurrentAdvisorData;
