import React, { useEffect, useState } from "react";
import BasicSnackbar from "../components/BasicSnackbar";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { tokenValid } from "../helpers/tokenValid";
import Typography from '@mui/material/Typography';
import Grid from "@mui/system/Unstable_Grid";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';

const YeartoDateCost = ({ onError }) => {
  const { ...rest } = useAuth();
  const [yeartoDateCostData, setYeartoDateCostData] = useState({
    data: "",
    uData:"",
    xLabels:"",
    max: 0,
    isLoading: true,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    const YeartoDateCostUrl = providerUrl + "/api/ytdcost" + urlParameters;
    executeFetch("GET", YeartoDateCostUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {


          let uData = [];
          let xLabels = [];

          if( response.data !== "Too many requests. Please retry.") {

          response.data.forEach((element) => {
            uData.push(element.cost);
            xLabels.push(element.monthname);
          });
        }
          setYeartoDateCostData({
            data: response.data,
            uData: uData,
            xLabels:xLabels,
            max: 8000,
            isLoading: false,
          });
        }
      }
    });
  }, []);

  return (
    <>
      <BasicSnackbar
        open={snackbar.open}
        severity={snackbar.severity}
        message={snackbar.message}
        onClose={handleClose}
        vertical="top"
        horizontal="right"
      />
       <Grid
          container
          spacing={0}>
            <Grid xs={12} >
            <Card>

            <CardHeader
      
              title="Cost to Date (Monthly)"
              // subheader="Current Month"
              >
      
            </CardHeader>
          <CardContent>
      { yeartoDateCostData.isLoading ? null :   



    <LineChart
    slotProps={{
      legend: {
        direction: 'column',
        markGap: 5,
        itemGap: 5,
        labelStyle: {
          fontSize: 11,
        },
      }
    }}
    width={500}
    height={200}
      
      series={[{ 
          data: yeartoDateCostData.uData, 
          label: 'Month', 
          area: true, 
          showMark: true 
      }]}
      xAxis={[{ 
          scaleType: 'point', 
          data: yeartoDateCostData.xLabels,
      }]}
      sx={{
        [`& .${lineElementClasses.root}`]: {
          display: 'none',
        },
      }}
    />
     
      }
          </CardContent>
        </Card>
        </Grid>
        </Grid>
        
      
    </>
  );
};

export default YeartoDateCost;
