import { jwtDecode } from "jwt-decode";

export const tokenValid = (token) => {
  if (token) {
    const expiry = jwtDecode(token).exp;
    const now = Date.now() / 1000;
    return now < expiry;
  } else {
    return false;
  }
};
