import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import BasicSnackbar from "../components/BasicSnackbar";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";

const AdminBulkTaxonomyAddTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const [updateTaxonomyData, setUpdateTaxonomyData] = useState({
    payload: null,
    update: false,
  });

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const [state, setState] = useState({
    loading: false,
    fileName: "",
    file: "",
    errorMsg: "",
    invalidFile: true,
    showAlert: false,
  });

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    var valid = false;

    fileReader.onload = function (event) {
      const text = event.target.result;

      var array = text.toString().split(/\r\n|\r|\n/);

      for (const r of array) {
        valid = r.split(",").length - 1 === 6;

        if (!valid) {
          setState((prevState) => {
            return {
              ...prevState,
              errorMsg: "File Format Is Invalid",
              invalidFile: true,
              showAlert: true,
            };
          });
          break;
        }
      }

      if (valid) {
        setState((prevState) => {
          return {
            ...prevState,
            file: e.target.files[0],
            fileName: e.target.files[0].name,
            errorMsg: "",
            invalidFile: false,
            showAlert: false,
          };
        });
      }
    };

    fileReader.readAsText(e.target.files[0]);
  };

  const refreshTaxonomyData = () => {};
  const taxonomyInsertUrl = providerUrl + "/api/bulk_insert_taxonomy";

  useEffect(() => {
    refreshTaxonomyData();
  }, [state]);

  const csvFileToArray = (string) => {
    var array = string.toString().split(/\r\n|\r|\n/);

    var data = [];

    let index = 0;
    for (const r of array) {
      if (index > 0) {
        let row = r.toString().split(",");
        data.push(row);
      }
      index++;
    }

    console.log({ data });

    const payload = {
      taxonomy: [],
    };

    for (const r of data) {
      if (r[0] !== "") {
        let p = {
          index: 0,
          customer: rest.subscription.subscription,
          tagName: r[0],
          aliases: r[1],
          description: r[2],
          isRequired: r[3],
          finOps: r[4],
          operations: r[5],
          patching: r[6],
        };
        payload.taxonomy.push(p);
      }
    }
    setUpdateTaxonomyData({ payload: payload, update: true });
  };

  useEffect(() => {
    if (updateTaxonomyData.update) {
      const payload = updateTaxonomyData.payload;
      setUpdateTaxonomyData({ payload: null, update: false });
      executeFetch("POST", taxonomyInsertUrl, rest.token, payload).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } else {
            setState((prevState) => {
              return { ...prevState, loading: false };
            });
          }
        }
      });
    }
  }, [executeFetch, updateTaxonomyData]);

  const handleOnSubmit = (e) => {
    setState((prevState) => {
      return { ...prevState, loading: true };
    });

    if (state.file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(state.file);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ color: "red" }}></h1>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Typography
          style={{ marginRight: 10 }}
          variant="h6"
        >
          Import Taxonomy CSV File
        </Typography>
        <br></br>
        {!state.loading ? (
          <form>
            <Button
              component="label"
              variant="outlined"
              startIcon={<UploadFileIcon />}
              sx={{ marginRight: "1rem" }}
            >
              Select CSV
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={(e) => handleOnChange(e)}
              />
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={state.invalidFile}
              onClick={(e) => handleOnSubmit()}
            >
              IMPORT CSV
            </Button>
          </form>
        ) : null}
        <Box>
          <i>{state.fileName}</i>
        </Box>
        <br />
        {state.loading ? <CircularProgress color="secondary" /> : null}

        <Box sx={{ flexGrow: 1 }}>
          {state.showAlert ? (
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs
              ></Grid>
              <Grid
                item
                xs={3}
              >
                <Item>
                  <Alert severity="error">
                    <Typography variant="h6">{state.errorMsg}</Typography>
                  </Alert>
                </Item>
              </Grid>
              <Grid
                item
                xs
              ></Grid>
            </Grid>
          ) : null}
          <h1 style={{ color: "red" }}></h1>
          <Typography
            style={{ marginRight: 10 }}
            variant="h6"
          >
            File Schema
          </Typography>
          <br></br>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs
            ></Grid>
            <Grid
              item
              xs={6}
            >
              <Item>
                <Typography variant="subtitle1">
                  tagName: No commas, space delimited
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  aliases: No commas, space delimited
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  description: No commas, space delimited
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  isRequired: 0 for false 1 for true
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  finOps: 0 for false 1 for true
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  operations: 0 for false 1 for true
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  patching: 0 for false 1 for true
                </Typography>
                <br />
              </Item>
            </Grid>
            <Grid
              item
              xs
            ></Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default AdminBulkTaxonomyAddTable;
