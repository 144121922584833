import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from "recharts";

import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { SubscriptionName } from "../components/CurrentSubscription";
import { renderCellExpand } from "../components/RenderCellExpand";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import Grid from "@mui/system/Unstable_Grid";

const userTableStyles = {
  height: "1000px",
};

const TagResourcesTable = ({ onError }) => {
  const { ...rest } = useAuth();
  //const [startDate, setStartDate] = useState('');
  //const [endDate, setEndDate] = useState('');
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [params, setParams] = useState({
    startDate: dayjs().subtract(30, 'day').format("MM/DD/YYYY"),
    endDate:  dayjs().format("MM/DD/YYYY"),
  });
  
  const handleStartDateChange = (event) => {

    let nv = event.$d.toLocaleDateString();
    setParams((prevState) => {
      return { ...prevState, startDate: nv };
    });
  };

  const handleEndDateChange = (event) => {
    setParams((prevState) => {
      return { ...prevState, endDate: event.$d.toLocaleDateString() };
    });
  };

  const [taggedResourcesData, settaggedResourcesData] = useState({
    data: [],
    isLoading: true
  })

  const [untaggedResourcesData, setuntaggedResourcesData] = useState({
    data: [],
    isLoading: true
  })

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open: false});
  };

  let urlParameters = "";
  let urlSubscription = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
    urlSubscription = rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  let taggedResourceUrl =
    providerUrl +
    "/api/report_tag_resource" +
    urlParameters;

  let untaggedResourceUrl =
    providerUrl +
    "/api/report_untagged_resource" +
    urlParameters;

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
   
    let caUrlParameters = "";

    caUrlParameters = "&startDate=" + params.startDate + "&endDate=" + params.endDate;

    let reportUrl =
    taggedResourceUrl + caUrlParameters
   
    console.log(taggedResourceUrl)  

    executeFetch("GET", reportUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          settaggedResourcesData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    params.startDate,
    params.endDate,
    rest.refreshToken
  ]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
   
    let caUrlParameters = "";

    caUrlParameters = "&startDate=" + params.startDate + "&endDate=" + params.endDate;

    let reportUrl =
    untaggedResourceUrl + caUrlParameters
   
    console.log(untaggedResourceUrl)  

    executeFetch("GET", reportUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setuntaggedResourcesData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    params.startDate,
    params.endDate,
    rest.refreshToken
  ]);
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto", pl: "10px" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        {/* <Typography variant="h5">
          <center>Tagged Resources</center>
          <center>
            <SubscriptionName />
          </center>
        </Typography> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <center>
          <FormControl style={{ minWidth: 420 }}>
            <DatePicker 
              label="Start Date" 
              inputFormat="E MMM dd yyyy"
              value={dayjs(params.startDate)} 
              onChange={handleStartDateChange}
            />
          </FormControl>
          <FormControl style={{ minWidth: 420 }}>
            <DatePicker 
              label="End Date"
              inputFormat="E MMM dd yyyy" 
              value={dayjs(params.endDate)} 
              onChange={handleEndDateChange}
            />
          </FormControl>
          </center>
        </LocalizationProvider>
        <br/>
      <ResponsiveContainer
        width="100%"
        height={200}
      >
        <Grid
          container
          spacing={0} 
        >
          <Grid xs={12} height={50}>
            <Typography variant="h5">
              <center>Tag Compliant Resources</center>
            </Typography>
          </Grid>
          <Grid xs={12}>
          <center>
            <BarChart width={1500} height={250} data={taggedResourcesData.data}>
              <Tooltip />
              <Legend />
              <Bar dataKey="resourceCost" fill="green" barSize={30}>
                <LabelList dataKey="resourceCost" position="top" />
              </Bar>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="taggedResources" />
              <YAxis />
            </BarChart>
            </center>
          </Grid>
          <Grid xs={12} height={50}>
            <Typography variant="h5">
              <center>Non Compliant Resources</center>
            </Typography>
          </Grid>
          <Grid xs={12}>
          <center>
            <BarChart width={1500} height={250} data={untaggedResourcesData.data}>
              <Tooltip />
              <Legend />
              <Bar dataKey="resourceCost" fill="green" barSize={30}>
                <LabelList dataKey="resourceCost" position="top" />
              </Bar>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="untaggedResources" />
              <YAxis />
            </BarChart>
            </center>
          </Grid>
         
        </Grid>        
      </ResponsiveContainer>
      </Box>
    </>
  );
};

export default TagResourcesTable;
