import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { modalStyles } from "../components/PopupModal/styles";
import { useForm } from "react-hook-form";
import { hoverStyles } from "../styles/theme";

export default function CustomerModal({ ...rest }) {
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return rest.customerData;
    }, [rest]),
    mode: "onTouched",
  });
  const [validationError, setValidationError] = useState({
    error: "",
  });
  const [submitData, setSubmitData] = useState({
    id: 0,
    customer_id: "",
    name: "",
    is_active: "",
    created_at: new Date().getDate(),
    updated_at: new Date().getDate(),
  });

  useEffect(() => {
    const sub = watch((value) => {
      setValidationError({ error: "" });
    });
    return () => sub.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(rest.customerData);
  }, [rest.customerData]);

  useEffect(() => {}, [validationError]);

  useEffect(() => {
    if (submitData.customer_id !== "") {
      rest.onClose();
      rest.saveData(submitData);
    }
  }, [submitData]);

  const onFormSubmit = ({
    id, 
    customer_id,
    name,
    is_active,
    created_at,
    updated_at,
  }) => {
    setSubmitData({
      id: id,
      customer_id: customer_id,
      name: name,
      is_active: "true",
      created_at: created_at,
      updated_at: updated_at,
    });
  };

  return (
    <Modal
      open={rest.open}
      onClose={rest.onClose}
    >
      <Box sx={modalStyles.wrapper}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            "& .MuiTextField-root": { m: 1, width: 500 },
            border: "2px solid black",
            p: 3,
          }}
        >
          <Typography variant="h6">Google Customer Information</Typography>

          <TextField
            {...register("customer_id", {
              required: true,
              pattern:
                /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
            })}
            required
            label="Customer ID"
            variant="outlined"
            error={!!errors.customer_id}
          />
          {errors.customer_id &&
            errors.customer_id.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
            )}
          {errors.customer_id &&
            errors.customer_id.type === "pattern" && (
              <Alert severity="error">Customer ID is not valid.</Alert>
            )}


          <TextField
            {...register( "name", { required: true })}
            required
            label="Display Name"
            variant="outlined"
            error={!!errors.display_name}
          />
          {errors.name && errors.name.type === "required" && (
            <Alert severity="error">This field is required</Alert>
          )}
          
          {rest.customerData.id === 0 ? (
            <Button
              variant="outlined"
              sx={hoverStyles}
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Add Customer
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={hoverStyles}
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Update Customer
            </Button>
          )}
          <Typography
            style={{ color: "red" }}
            variant="subtitle1"
          >
            {validationError.error}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
