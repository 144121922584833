import RegionDataTable from "../azure-components/RegionData";
import Map from "../components/Map.tsx";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Box from "@mui/material/Box";

export function RegPage() {
  return (
    <>
      <AuthenticatedTemplate>
        <div className="flex">
          <Box sx={{ width: 7 / 10, margin: 0 }}>
            <Map />
          </Box>
          <div>
            <RegionDataTable />
          </div>
        </div>
      </AuthenticatedTemplate>
    </>
  );
}
