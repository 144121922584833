import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { renderCellExpand } from "../components/RenderCellExpand";
import BasicSnackbar from "../components/BasicSnackbar";
import { v4 } from "uuid";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const userTableStyles = {
  height: "700px",
};

const AdminSubscriptionBulkAddDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [subscriptionsData, setSubscriptionsData] = useState({
    data: [],
    isLoading: true,
  });
  const [refreshSubscriptionData, setRefreshSubscriptionData] = useState(true);
  const [updateSubscriptionData, setUpdateSubscriptionData] = useState({
    payloads: [],
    update: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const currentSubscriptionsUrl =
    providerUrl + "/api/subscriptions?cachebreaker=" + v4();
  const tenantSubscriptionsUrl =
    providerUrl +
    "/api/tenant_subscriptions?subscription=" +
    rest.subscription.subscription;

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (refreshSubscriptionData) {
      executeFetch("GET", tenantSubscriptionsUrl, rest.token).then(
        (response) => {
          if (response) {
            if (response.isError) {
              console.log(response.error);
              setSnackbar({
                open: true,
                message: response.error,
                severity: "error",
              });
            } else {
              let tenantSubscriptionsData = response.data.value;
              executeFetch("GET", currentSubscriptionsUrl, rest.token).then(
                (subresponse) => {
                  if (subresponse) {
                    if (subresponse.isError) {
                      console.log(subresponse.error);
                      setSnackbar({
                        open: true,
                        message: subresponse.error,
                        severity: "error",
                      });
                    } else {
                      const currentSubscriptionsData = subresponse.data;

                      let mergedSubscriptionsData = [];
                      tenantSubscriptionsData.forEach((element) => {
                        element.exists = false;
                        for (
                          let i = 0;
                          i < currentSubscriptionsData.length;
                          i++
                        ) {
                          if (
                            element.subscriptionId ===
                            currentSubscriptionsData[i].subscription
                          ) {
                            element.exists = true;
                          }
                        }
                      });

                      tenantSubscriptionsData.forEach((elem) => {
                        if (elem.exists !== true)
                          mergedSubscriptionsData.push(elem);
                      });
                      setSubscriptionsData({
                        data: mergedSubscriptionsData,
                        isLoading: false,
                      });
                      setRefreshSubscriptionData(false);
                    }
                  }
                }
              );
            }
          }
        }
      );
    }
  }, [executeFetch, refreshSubscriptionData, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (updateSubscriptionData.update) {
      const payloads = updateSubscriptionData.payloads;
      setUpdateSubscriptionData({ payloads: [], update: false });
      const subscriptionsUrl = providerUrl + "/api/insert_subscription";
      payloads.forEach((element) => {
        const payload = {
          id: 0,
          name: element.displayName,
          friendlyName: element.displayName,
          subscription: element.subscriptionId,
          tenant: element.tenantId,
          clientID: rest.subscription.clientID,
          clientSecret: rest.subscription.clientSecret,
          active: "0",
          managedIdentity: "false",
        };
        executeFetch("POST", subscriptionsUrl, rest.token, payload).then(
          (response) => {
            if (response) {
              if (response.isError) {
                console.log(response.error);
                setSnackbar({
                  open: true,
                  message: response.error,
                  severity: "error",
                });
              }
            }
          }
        );
      });
      setSubscriptionsData({
        data: [],
        isLoading: true,
      });
      setRefreshSubscriptionData(true);
    }
  }, [executeFetch, updateSubscriptionData, rest.refreshToken]);

  const columns = [
    {
      field: "displayName",
      headerName: "Display Name",
      width: 450,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "subscriptionId",
      headerName: "Subscription ID",
      width: 350,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 350,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "exists",
      headerName: "Exists",
      width: 150,
      editable: false,
      renderCell: renderCellExpand,
    },
  ];

  const onSubmit = (e) => {
    let updatePayloads = [];
    selections.selectedData.forEach((element) => {
      for (let i = 0; i < subscriptionsData.data.length; i++) {
        if (subscriptionsData.data[i].id === element) {
          updatePayloads.push(subscriptionsData.data[i]);
        }
      }
    });

    setUpdateSubscriptionData({ payloads: updatePayloads, update: true });
  };

  const [selections, setSelectionModel] = useState({
    selectedData: [],
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <br />
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Typography variant="h6">Bulk Add New Subscriptions</Typography>
        <Button
          variant="outlined"
          sx={hoverStyles}
          color="primary"
          onClick={(e) => onSubmit()}
        >
          IMPORT SUBSCRIPTIONS
        </Button>
        <br />

        <DataGrid
          rows={subscriptionsData.data}
          rowCount={subscriptionsData.data.total}
          columns={columns}
          loading={subscriptionsData.isLoading}
          sx={userTableStyles}
          checkboxSelection
          hideFooterPagination
          onRowSelectionModelChange={(newSelection) => {
            setSelectionModel((old) => ({
              ...old,
              selectedData: newSelection,
            }));
          }}
          columnVisibilityModel={{
            exists: false,
          }}
        />

        <br />
      </div>
    </ThemeProvider>
  );
};

export default AdminSubscriptionBulkAddDataTable;
