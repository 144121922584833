import React from "react";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import Tagging from "@mui/icons-material/More";
import Task from "@mui/icons-material/Task";
import Person from "@mui/icons-material/Person";
import Business from "@mui/icons-material/Business";
import Decomp from "@mui/icons-material/BlurLinearOutlined";
import Analytics from "@mui/icons-material/Analytics";

export const MenuGoogle = () => {
  return (
    <React.Fragment>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/subscriptions">
          <ListItemIcon>
            <Tooltip title="Customers">
              <Person />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/organizations">
          <ListItemIcon>
            <Tooltip title="Organizations">
              <Business />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Organizations" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/tagging">
          <ListItemIcon>
            <Tooltip title="Tagging">
              <Tagging />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Tagging" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/decomposition">
          <ListItemIcon>
            <Tooltip title="Decomposition">
              <Decomp />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Decomposition" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/tasks">
          <ListItemIcon>
            <Tooltip title="Tasks">
              <Task />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Tasks" />
        </ListItemButton>
      </div>
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/reportingGCP">
          <ListItemIcon>
            <Tooltip title="Reporting">
              <Analytics />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Reporting" />
        </ListItemButton>
      </div>
    </React.Fragment>
  );
};
