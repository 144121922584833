import React from "react";
import Typography from "@mui/material/Typography";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import AdminSubscriptionDataTable from "../azure-components/AdminSubscriptionData";
import AdminSubscriptionBulkAddDataTable from "../azure-components/AdminBulkSubscriptionAdd";
import AdminTaxonomyDataTable from "../azure-components/AdminTaxonomyData";
import AdminGCPTaxonomyDataTable from "../gcp-components/AdminGCPTaxonomyData";
import AdminProviderDataTable from "../components/AdminProviderData";
import AdminGCPCustomerDataTable from "../gcp-components/AdminGCPCustomerData";
import GCPAdminBulkTaxonomyAddTable from "../gcp-components/AdminGCPBulkTaxonomyAdd";
import AdminBulkTaxonomyAddTable from "../azure-components/AdminBulkTaxonomyAdd";
import AdminAlertDataTable from "../azure-components/AdminAlertData";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let isAzure = "";
try {
  const providerData = localStorage.getItem("provider");
  const provider = JSON.parse(providerData);
  if (provider !== "") {
    isAzure = provider.provider === "Azure" ? true : false;
  }
} catch (e) {}

export const AdminPage = ({ isAdmin }) => {
  const { ...rest } = useAuth();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthenticatedTemplate>
          {rest.isAdmin ? (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  paddingTop: "25px",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Providers"
                    {...a11yProps(0)}
                  />
                  {isAzure ? (
                    <Tab
                      label="Subscriptions"
                      {...a11yProps(1)}
                    />
                  ) : (
                    <Tab
                      label="Customers"
                      {...a11yProps(1)}
                    />
                  )}

                  <Tab
                    label="Taxonomy"
                    {...a11yProps(2)}
                  />

                  <Tab
                    label="Taxonomy Bulk Add"
                    {...a11yProps(3)}
                  />

                  {isAzure ? (
                    <Tab
                      label="Subscription Bulk Add"
                      {...a11yProps(4)}
                    />
                  ) : // <Tab label="Organizations" {...a11yProps(4)} />
                  null}

                  {isAzure ? (
                    <Tab
                      label="Alerts"
                      {...a11yProps(5)}
                    />
                  ) : // <Tab label="Organizations" {...a11yProps(4)} />
                  null}
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}
              >
                <AdminProviderDataTable />
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
              >
                {isAzure ? (
                  <AdminSubscriptionDataTable />
                ) : (
                  <AdminGCPCustomerDataTable />
                )}
              </TabPanel>

              <TabPanel
                value={value}
                index={2}
              >
                {isAzure ? (
                  <AdminTaxonomyDataTable />
                ) : (
                  <AdminGCPTaxonomyDataTable />
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={3}
              >
                {isAzure ? (
                  <AdminBulkTaxonomyAddTable />
                ) : (
                  <GCPAdminBulkTaxonomyAddTable />
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={4}
              >
                {isAzure ? (
                  <AdminSubscriptionBulkAddDataTable />
                ) : // <AdminGCPOrganizationDataTable />
                null}
              </TabPanel>
              <TabPanel
                value={value}
                index={5}
              >
                {isAzure ? (
                  <AdminAlertDataTable />
                ) : // <AdminGCPOrganizationDataTable />
                null}
              </TabPanel>
            </Box>
          ) : (
            <Typography variant="h6">
              Access Denied. You do not have permission to access this page.
            </Typography>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Typography variant="h6">
            Please sign-in to obtain your Azure tenant report.
          </Typography>
        </UnauthenticatedTemplate>
      </ThemeProvider>
    </>
  );
};
