import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { modalStyles } from "../components/PopupModal/styles";
import { useForm } from "react-hook-form";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";

export default function SubscriptionModal({ ...rest }) {
  const { ...restModal } = useAuth();
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return rest.subscriptionData;
    }, [rest]),
    mode: "onTouched",
  });
  const [validationError, setValidationError] = useState({
    error: "",
  });
  const [submitData, setSubmitData] = useState({
    id: 0,
    friendlyName: "",
    name: "",
    subscription: "",
    tenant: "",
    clientID: "",
    clientSecret: "",
    active: "",
    managedIdentity: "",
  });

  let providerUrl = "";
  if (restModal.provider !== "") {
    providerUrl = restModal.provider.url;
  }
  const checkPrincipalUrl = providerUrl + "/api/validate_principal";

  useEffect(() => {
    const sub = watch((value) => {
      setValidationError({ error: "" });
    });
    return () => sub.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(rest.subscriptionData);
  }, [rest.subscriptionData]);

  useEffect(() => {}, [validationError]);

  useEffect(() => {
    if (submitData.subscription_id !== "" && submitData.subscription !== "") {
      const validate_payload = {
        subscription: submitData.subscription,
        tenant: submitData.tenant,
        client: submitData.clientID,
        secret: submitData.clientSecret,
      };

      executeFetch("POST", checkPrincipalUrl, restModal.token, validate_payload).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setValidationError({ error: response.error.error_description });
          } else {
            if (response.data.error) {
              setValidationError({ error: response.data.error_description });
            } else {
              rest.onClose();
              rest.saveData(submitData);
            }
          }
        }
      });
    }
  }, [executeFetch, submitData]);

  const onFormSubmit = ({
    id,
    display_name,
    subscription_name,
    subscription_id,
    tenant_id,
    client_id,
    client_secret,
    active,
  }) => {
    setSubmitData({
      id: id,
      name: display_name,
      friendlyName: subscription_name,
      subscription: subscription_id,
      tenant: tenant_id,
      clientID: client_id,
      clientSecret: client_secret,
      active: active,
      managedIdentity: "",
    });
  };

  return (
    <Modal
      open={rest.open}
      onClose={rest.onClose}
    >
      <Box sx={modalStyles.wrapper}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            "& .MuiTextField-root": { m: 1, width: 500 },
            border: "2px solid black",
            p: 3,
          }}
        >
          <Typography variant="h6">Azure Subscription Information</Typography>

          <TextField
            {...register("display_name", { required: true })}
            required
            label="Display Name"
            variant="outlined"
            error={!!errors.display_name}
          />
          {errors.display_name && errors.display_name.type === "required" && (
            <Alert severity="error">This field is required</Alert>
          )}

          <TextField
            {...register("subscription_name", {
              required: true,
            })}
            required
            label="Subscription Name"
            variant="outlined"
            error={!!errors.subscription_name}
          />
          {errors.subscription_name &&
            errors.subscription_name.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
            )}

          <TextField
            {...register("subscription_id", {
              required: true,
              pattern:
                /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
            })}
            required
            label="Subscription ID"
            variant="outlined"
            error={!!errors.subscription_id}
          />
          {errors.subscription_id &&
            errors.subscription_id.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
            )}
          {errors.subscription_id &&
            errors.subscription_id.type === "pattern" && (
              <Alert severity="error">Subsription ID is not valid.</Alert>
            )}

          <TextField
            {...register("tenant_id", {
              required: true,
              pattern:
                /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
            })}
            required
            label="Tenant ID"
            variant="outlined"
            error={!!errors.tenant_id}
          />
          {errors.tenant_id && errors.tenant_id.type === "required" && (
            <Alert severity="error">This field is required.</Alert>
          )}
          {errors.tenant_id && errors.tenant_id.type === "pattern" && (
            <Alert severity="error">Tenant ID is not valid.</Alert>
          )}

          <TextField
            {...register("client_id", {
              required: true,
              pattern:
                /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
            })}
            required
            label="Client ID"
            variant="outlined"
            error={!!errors.client_id}
          />
          {errors.cleint_id && errors.client_id.type === "required" && (
            <Alert severity="error">This field is required.</Alert>
          )}
          {errors.client_id && errors.client_id.type === "pattern" && (
            <Alert severity="error">Client ID is not valid.</Alert>
          )}

          <TextField
            {...register("client_secret", {
              required: true,
            })}
            required
            label="Client Secret"
            type="password"
            variant="outlined"
            error={!!errors.client_secret}
          />
          {errors.client_secret && errors.client_secret.type === "required" && (
            <Alert severity="error">This field is required.</Alert>
          )}
          {rest.subscriptionData.id === 0 ? (
            <Button
              variant="contained"
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Add Subscription
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Update Subscription
            </Button>
          )}
          <Typography
            style={{ color: "red" }}
            variant="subtitle1"
          >
            {validationError.error}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
