import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { SubscriptionName } from "../components/CurrentSubscription";
import { renderCellExpand } from "../components/RenderCellExpand";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  {
    field: "resName",
    headerName: "Resource Name",
    width: 350,
    renderCell: renderCellExpand,
  },
  {
    field: "resLoc",
    headerName: "Location",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "meterCategory",
    headerName: "Meter Category",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "product",
    headerName: "Product",
    width: 450,
    renderCell: renderCellExpand,
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    field: "usageQuan",
    headerName: "Quantity",
    width: 140,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(4)).toLocaleString();
      return `${valueFormatted}`;
    },
  },
  {
    field: "unitPrice",
    headerName: "Price",
    width: 150,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return `$${valueFormatted}`;
    },
  },
  {
    field: "cost",
    headerName: "Cost",
    width: 150,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return `$${valueFormatted}`;
    },
  },
];

const userTableStyles = {
  height: "1000px",
};

const CostAnalysisTable = ({ onError }) => {
  const { ...rest } = useAuth();
  const [currentLocation, setCurrentLocation] = useState('');
  const [currentMeterCategory, setCurrentMeterCategory] = useState('');
  const [consumptionLocations, setConsumptionLocations] = useState({
    locations: []
  });
  const [consumptionMeterCategories, setConsumptionMeterCategories] = useState({
    meter_categories: []
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  const [costAnalysisData, setCostAnalysisData] = useState({
    data: [],
    isLoading: true
  })

  const handleLocationChange = (event) => {
    console.log(event.target.value )
    setCurrentLocation({currentLocation: event.target.value });
  };

  const handleMeterCategoryChange = (event) => {
    console.log(event.target.value )
    setCurrentMeterCategory({ currentMeterCategory: event.target.value, });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open: false});
  };

  let urlParameters = "";
  let urlSubscription = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
    urlSubscription = rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  let costAnalysisUrl =
    providerUrl +
    "/api/report_cost_analysis" +
    urlParameters;

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const locationsUrl =
      providerUrl +
      "/api/consumption_locations?" +
      "subscription=" +
      urlSubscription;
    executeFetch("GET", locationsUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          let data = response.data;
          let location_options = [];
          let location_option = "";
          data.forEach((element) => {
            location_option = {
              value: element.resLoc,
              label: element.resLoc,
            };
            location_options.push(location_option);
          });

          setCurrentLocation({currentLocation: "" });

          setConsumptionLocations({ locations: location_options });
        }
      }
    })
  }, [executeFetch, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const meterCategoriesUrl =
      providerUrl +
      "/api/consumption_meter_categories?" +
      "subscription=" +
      urlSubscription;
    executeFetch("GET", meterCategoriesUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
            let data = response.data;
            let meter_categories_options = [];
            let meter_categories_option = "";

            data.forEach((element) => {
              meter_categories_option = {
                value: element.meterCategory,
                label: element.meterCategory,
              };
              meter_categories_options.push(meter_categories_option);
            });

            setConsumptionMeterCategories({meter_categories: meter_categories_options});
        }
      }
    })
  }, [executeFetch, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
   
    let caUrlParameters = "";

    if (currentLocation !== "") {
      caUrlParameters =
        caUrlParameters + "&location=" + currentLocation.currentLocation;
    }
    else {
      caUrlParameters = 
        caUrlParameters + "&location="
    }

    if (currentMeterCategory !== "") {
      caUrlParameters =
        caUrlParameters + "&meterCategory=" + currentMeterCategory.currentMeterCategory;
    }
    else
    {
      caUrlParameters = 
        caUrlParameters + "&meterCategory="
    }

    costAnalysisUrl =
      costAnalysisUrl + caUrlParameters
   
    console.log(costAnalysisUrl)  

    executeFetch("GET", costAnalysisUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setCostAnalysisData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    currentLocation,
    currentMeterCategory,
    rest.refreshToken
  ]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto", pl: "10px" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Typography variant="h5">
          <center>Cost Analysis</center>
          <center>
            <SubscriptionName />
          </center>
        </Typography>
        <FormControl style={{ minWidth: 420 }}>
          <InputLabel id="demo-simple-select-label">
            Select a location
          </InputLabel>
          <Select
            class=""
            onChange={handleLocationChange}
            value={currentLocation.currentLocation}
            label="Select a location"
          >
            <MenuItem value={""}>All</MenuItem>
            {consumptionLocations.locations.map((location) => (
              <MenuItem value={location.value}>{location.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 420 }}>
          <InputLabel id="demo-simple-select-label">
            Select a Meter Category
          </InputLabel>
          <Select
            class=""
            onChange={handleMeterCategoryChange}
            value={currentMeterCategory.currentMeterCategory}
            label="Select a Meter Category"
          >
            <MenuItem value={""}>All</MenuItem>
            {consumptionMeterCategories.meter_categories.map((location) => (
              <MenuItem value={location.value}>{location.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <DataGrid
          rows={costAnalysisData.data}
          rowCount={costAnalysisData.data.length}
          columns={columns}
          loading={costAnalysisData.isLoading}
          sx={userTableStyles}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default CostAnalysisTable;
