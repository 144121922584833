import TaggingData from "../azure-components/TaggingData";
import GCPTaggingData from "../gcp-components/GCPTaggingData";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useAuth } from "../authContext";

export function TagPage({ isWriter, isAdmin }) {
  const { ...rest } = useAuth();
  return (
    <>
      <AuthenticatedTemplate>
        {rest.provider.provider === "Azure" ? <TaggingData /> : null}
        {rest.provider.provider === "GCP" ? <GCPTaggingData /> : null}
      </AuthenticatedTemplate>
    </>
  );
}
