import {
  AuthenticatedTemplate,
} from "@azure/msal-react";
import SubscriptionDataTable from "../components/SubscriptionData";
import CustomerDataTable from "../gcp-components/GCPCustomerData";
import { useAuth } from "../authContext";

export const SubscriptionPage = () => {
  const { ...rest } = useAuth();
  return (
    <>
      <AuthenticatedTemplate>
        {rest.provider.provider === "Azure" ? <SubscriptionDataTable /> : null}
        {rest.provider.provider === "GCP" ? <CustomerDataTable /> : null}
      </AuthenticatedTemplate>
    </>
  );
};
