import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import { renderCellExpand } from "../components/RenderCellExpand";
import Box from "@mui/material/Box";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";

// customer_id": "98212c9c-4941-43a7-b843-f13ab7f1c2d4",
//         "organization_id": 785432631352,
//         "billing_account_id": "01FE5B-E6D040-91F7F0",
//         "service_description": "Compute Engine",
//         "sku_description": "Network Internet Ingress from China to Americas",
//         "usage_date": "2023-11-14",
//         "project_id": "k9-dev-394817",
//         "location": "us-central1",
//         "resource_name": "//compute.googleapis.com/projects/38522773391/global/forwarding_rule/6986477708433798881",
//         "effective_price": 0,
//         "total_cost": 0.0,
//         "currency": "USD",
//         "total_usage": 1017.0,
//         "usage_unit": "bytes",
//         "usage_amount_in_pricing_units": 9.47e-07,
//         "usage_pricing_unit": "gibibyte",
//         "price_unit": "gibibyte",
//         "cost_at_list": 0.0,

const columns = [
  { field: "id", hide: true },
  {
    field: "billing_account_id",
    hide: true,
    headerName: "Account ID",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    field: "total_cost",
    headerName: "Cost",
    width: 125,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return "$" + valueFormatted;
    },
  },
  {
    field: "effective_price",
    headerName: "Effective Cost",
    width: 140,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return "$" + valueFormatted;
    },
  },
  {
    field: "cost_at_list",
    headerName: "Costs at List",
    width: 125,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return "$" + valueFormatted;
    },
  },
  {
    field: "total_usage",
    headerName: "Total Usage",
    width: 125,
    renderCell: renderCellExpand,
  },
  {
    field: "sku_description",
    headerName: "SKU",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "service_description",
    headerName: "Service Description",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "location",
    headerName: "Location",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "project_id",
    headerName: "Project",
    width: 250,
    renderCell: renderCellExpand,
  },
  // { field: 'price_unit', headerName: 'Price Unit', width: 140 },
];

const userTableStyles = {
  height: "1000px",
};

const DecompositionDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  const [decompositionData, setDecompositionData] = useState({
    data: [],
    isLoading: true,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open:false});
  };

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url + "/customers/";
  }

  const summaryDecompositionUrl =
    providerUrl + rest.customer.customer_id + "/query/detailed_billing_exports";

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    executeFetch("GET", summaryDecompositionUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          setDecompositionData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        
        <DataGrid
          rows={decompositionData.data}
          rowCount={decompositionData.data.length}
          columns={columns}
          loading={decompositionData.isLoading}
          sx={userTableStyles}
          columnVisibilityModel={{
            id: false,
          }}
          hideFooterPagination

        />
      </Box>
    </>
  );
};

export default DecompositionDataTable;
