import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { renderCellExpand } from "../components/RenderCellExpand";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  { field: "status", headerName: "Status", width: 150 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "task_id", headerName: "Task ID", width: 300 },
  { field: "start", headerName: "Start", width: 150 },
  {
    field: "result",
    headerName: "Result",
    width: 900,
    renderCell: renderCellExpand,
  },
];
const userTableStyles = {
  height: "1000px",
};

const GCPTaskDataTable = ({ onError }) => {
  const { ...rest } = useAuth();
  const [taskData, setTaskData] = useState({
    data: [],
    isLoading: true,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open:false});
  };

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url + "/customers/";
  }

  const taskDataUrl =
    providerUrl + rest.customer.customer_id + "/tasks/status/incomplete";

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    executeFetch("GET", taskDataUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          let data = response.data;
          let index = 0;
            data.forEach((element) => {
              element.id = index;
              index++;
            });
          setTaskData({data: data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto", pl: "10px" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />

        <DataGrid
          rows={taskData.data}
          rowCount={taskData.data.length}
          columns={columns}
          loading={taskData.isLoading}
          sx={userTableStyles}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default GCPTaskDataTable;
