import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Typography, Button } from "@mui/material";
import BasicSnackbar from "../components/BasicSnackbar";
import BulkAdd from "../components/Modals/BulkAdd";
import TagConfirm from "../components/Modals/TagConfirm";
import TagApply from "../components/Modals/TagApply";
import { renderCellExpand } from "../components/RenderCellExpand";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  {
    field: "resName",
    headerName: "Resource Name",
    width: 250,
    renderCell: renderCellExpand,
  },
  { field: "resLocation", headerName: "Resource Location", width: 150 },
  {
    field: "resGroup",
    headerName: "Resource Group",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "resType",
    headerName: "Resource Type",
    width: 250,
    renderCell: renderCellExpand,
  },
  
  {
    field: "score",
    headerName: "Compliance Score",
    width: 150,
    description: "Score based on ratio of resource tags to required tags",
  },
  {
    field: "tagCount",
    headerName: "Tag Count",
    width: 100,
    description: "Number of tags currently on resource",
  },
  {
    field: "renderTags",
    headerName: "Tags",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "missingTagCount",
    headerName: "Missing Count",
    width: 120,
    description: "Number of recommended tags missing on resource",
  },
  {
    field: "renderMissingTags",
    headerName: "Missing Tags",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "resType",
    headerName: "Resource Type",
    width: 400,
    renderCell: renderCellExpand,
  },
];
const userTableStyles = {
  height: "1000px",
};

const TagDataTable = ({ isWriter, isAdmin }) => {
  const { ...rest } = useAuth();
  const [openTagging, setOpenTagging] = useState(false);
  const [openTagConfirm, setOpenTagConfirm] = useState(false);
  const [openTagApply, setOpenTagApply] = useState(false);
  const [expand, setExpand] = useState(false);
  const [enableTagUpdate, setEnableTagUpdate] = useState(true);
  const [performTagUpdate, setPerformTagUpdate] = useState(false);
  const [processTags, setProcessTags] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [updatedResources, setUpdatedResources] = useState([]);
  const [tagSet, setTagSet] = useState([]);
  const [tagTaxonomySet, setTagTaxonomySet] = useState([]);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const [tagComplianceData, setTagComplianceData] = useState({
    data: [],
    isLoading: true,
  });

  const [tagComplianceTotalData, setTagComplianceTotalData] = useState({
    resCount: "0",
    score: "0%",
    isLoading: true,
  });

  function UpdateTagsButton() {
    return (
      <Button
        disabled={!updatedResources.length || !enableTagUpdate}
        variant={"outlined"}
        sx={hoverStyles}
        onClick={(event) => {
          setOpenTagging(true);
          handleTaggingUpdate(updatedResources, event);
        }}
      >
        Update Tags
      </Button>
    );
  }

  function CustomToolbar() {
    return (
      <ThemeProvider theme={theme}>
        <GridToolbarContainer style={{ display: "flex" }}>
          <GridToolbarQuickFilter sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
          <Typography
            variant="h6"
            sx={{ mr: 2 }}
          >
            Total Compliance Score: {tagComplianceTotalData.score}
          </Typography>
          <Typography variant="h6">
            Taggable Resources: {tagComplianceTotalData.resCount}
          </Typography>
          <GridToolbarExport
            color="primary"
            variant="outlined"
            sx={{
              mr: 2,
              pt: 0.8,
              pb: 0.8,
              "&:hover": {
                color: "#fff",
                backgroundColor: "primary.main",
              },
            }}
          />
          {rest.isTagWriter || rest.isAdmin ? <UpdateTagsButton /> : null}
          <Button
            disabled={!enableTagUpdate}
            variant={"outlined"}
            sx={hoverStyles}
            onClick={(event) => {
              handleInventoryUpdate();
            }}
          >
            Refresh Tag Inventory
          </Button>
          <Typography variant="h6">
            Last Tag Inventory:{" "}
            {!tagComplianceData.isLoading && tagComplianceData.length > 0 
              ? tagComplianceData.data[0].date
              : null}{" "}
            UTC
          </Typography>
          <GridToolbarDensitySelector sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
        </GridToolbarContainer>
      </ThemeProvider>
    );
  }

  const handleInventoryUpdate = () => {
    setPerformTagUpdate(true);

    setSnackbar({
      open: true,
      message: "Tag inventory collection initiated in background.",
      severity: "info",
    });

    let counter = 0;
    const timer = setInterval(() => {
      if (timer && ++counter > 1) {
        clearInterval(timer);
      }
      setSnackbar({ open: false });
    }, 5000);
  };

  const mergeTagTaxonomy = (resTags, newTags) => {
    let mergeTags = [];
    let tempResTags = [];
    let resourceTags = resTags;
    const reg = /'/g;
    resourceTags = resourceTags.replace(reg, '"');
    resourceTags = JSON.parse(resourceTags);
    let index = 0;

    for (const [resName, resValue] of Object.entries(resourceTags)) {
      const resourceTag = {
        id: index,
        name: resName,
        value: resValue,
        replaced: false,
      };
      tempResTags = [...tempResTags, resourceTag];
      index++;
    }

    tempResTags.forEach((resTagElement) => {
      newTags.forEach((newTagElement) => {
        if (
          resTagElement.name.toLowerCase() === newTagElement.name.toLowerCase()
        ) {
          if (newTagElement.value !== "") {
            resTagElement.replaced = true;
          }
          if (newTagElement.remove) {
            resTagElement.replaced = true;
          }
        }
      });
    });

    index = 0;
    tempResTags.forEach((resTagElement) => {
      if (!resTagElement.replaced && resTagElement.value !== "") {
        const newTag = {
          id: index,
          name: resTagElement.name,
          value: resTagElement.value,
          modified: false,
        };
        mergeTags = [...mergeTags, newTag];
        index = index + 1;
      }
    });

    newTags.forEach((resTagElement) => {
      if (!resTagElement.replaced && resTagElement.value !== "") {
        const newTag = {
          id: index,
          name: resTagElement.name,
          value: resTagElement.value,
          modified: true,
        };
        mergeTags = [...mergeTags, newTag];
        index = index + 1;
      }
    });

    return mergeTags;
  };

  const handleTaggingUpdate = (param, event) => {
    if (param.length === 1) {
      let resourceTags = param[0].tags;
      resourceTags = JSON.parse(resourceTags);

      let resTags = [];
      let index = 1;
      for (const [name, value] of Object.entries(resourceTags)) {
        const newTag = {
          id: index,
          name: name,
          value: value,
          modified: false,
          remove: false,
        };
        resTags = [...resTags, newTag];
        index = index + 1;
      }
      let match = false;
      tagTaxonomySet.forEach((taxonomyElement) => {
        match = false;
        resTags.forEach((resElement) => {
          if (typeof resElement.name == "undefined") {
            return true;
          }
          if (
            resElement.name.toLowerCase() === taxonomyElement.name.toLowerCase()
          ) {
            match = true;
            return true;
          }
        });
        if (!match) {
          const newTag = {
            id: index,
            name: taxonomyElement.name,
            value: "",
            modified: false,
            remove: false,
          };
          resTags = [...resTags, newTag];
          index = index + 1;
        }
      });

      setTitle(param[0].resName);
      setSubTitle(
        "Required tags will be appended to any already existiing tags"
      );
      setTagSet(resTags);
    }
    if (param.length > 1) {
      setTitle("Add Required Tags to Selected Resources");
      setSubTitle(
        "Required tags will be appended if they do not currently exist"
      );
      setMultiple(true);
      setTagSet(tagTaxonomySet);
    }
  };

  const processChanges = () => {
    setOpenTagging(false);
    if (updatedResources.length === 1) {
      setTitle(updatedResources[0].resName);
      setSubTitle("Confirm proposed tag changes and confirm");
      setExpand(true);
      let newTagSet = [];
      let newRenderTag = "";
      let index = 1;
      tagSet.forEach((tagElement) => {
        if (tagElement.value !== "") {
          const newTag = {
            id: index,
            name: tagElement.name,
            value: tagElement.value,
            modified: tagElement.modified,
          };
          newRenderTag =
            newRenderTag +
            "'" +
            tagElement.name +
            "':'" +
            tagElement.value +
            "' | ";
          newTagSet = [...newTagSet, newTag];
        }
      });
      newRenderTag = newRenderTag.slice(0, -1);
      updatedResources[0].newTags = newTagSet;
      updatedResources[0].newRenderTags = newRenderTag;
    }
    if (updatedResources.length > 1) {
      setTitle("Bulk Resource Tagging Confirmation");
      setSubTitle("Confirm proposed tag changes and confirm");
      setExpand(false);
      updatedResources.forEach((element) => {
        const mergedTags = mergeTagTaxonomy(element.tags, tagSet);
        let newRenderTag = "";
        mergedTags.forEach((tagElement) => {
          newRenderTag =
            newRenderTag +
            "'" +
            tagElement.name +
            "':'" +
            tagElement.value +
            "' | ";
        });
        newRenderTag = newRenderTag.slice(0, -1);
        element.newTags = mergedTags;
        element.newRenderTags = newRenderTag;
      });
    }
    setOpenTagConfirm(true);
  };

  const applyChanges = () => {
    setOpenTagConfirm(false);
    setEnableTagUpdate(false);
    setProcessTags(true);
    setSelectionModel([]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  // Process inventory Request

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const tagCollectorUrl = providerUrl + "/api/collector?" + urlParameters;
    if (performTagUpdate) {
      executeFetch("GET", tagCollectorUrl, rest.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } else {
            setSnackbar({
              open: true,
              message:
                "Tag inventory collection complete.  Reload page to refresh data.",
              severity: "info",
            });
          }
        }
      });
    }
  }, [performTagUpdate, executeFetch, rest.refreshToken]);

  //Process Tag Updates

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const tagUpdateUrl = providerUrl + "/api/bulk_tagging?" + urlParameters;
    if (processTags) {
      setProcessTags(false);
      const payload = [];
      let tagElementPayload = [];
      updatedResources.forEach((resElement) => {
        const tagPayload = {
          resName: resElement.resName,
          resGroup: resElement.resGroup,
          resType: resElement.resType,
        };
        resElement.newTags.forEach((tagElement) => {
          let stripName = tagElement.name;
          let stripValue = tagElement.value;
          stripValue = stripValue.replace(/\\/g, "\\\\");
          stripValue = stripValue.replace(/"/g, '\\"');
          stripName = stripName.replace(/"/g, '\\"');
          const newTagElement = {
            name: stripName,
            value: stripValue,
          };
          tagElementPayload = [...tagElementPayload, newTagElement];
        });
        tagPayload.tags = tagElementPayload;
        payload.push(tagPayload);
      });

      executeFetch("POST", tagUpdateUrl, rest.token, payload).then(
        (response) => {
          if (response) {
            if (response.isError) {
              console.log(response.error);
              setSnackbar({
                open: true,
                message: response.error,
                severity: "error",
              });
            } else {
              setSnackbar({
                open: true,
                message:
                  "Tags update request submitted. Updates will be reflected in Azure in about 30 seconds.",
                severity: "info",
              });
            }
          }
        }
      );

      setEnableTagUpdate(true);
    }
  }, [processTags, executeFetch, rest.refreshToken]);

  // Render Tagging Page

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const tagTaxonomyUrl = providerUrl + "/api/tag_taxonomy";

    executeFetch("GET", tagTaxonomyUrl).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          let newTags = [];
          let index = 1;
          response.data.forEach((element) => {
            const newTag = {
              id: index,
              name: element.tagName,
              value: "",
              modified: false,
              remove: false,
            };
            newTags = [...newTags, newTag];
            index += 1;
          });
          setTagTaxonomySet(newTags);
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const tagComplianceUrl = providerUrl + "/api/tagging?" + urlParameters;
    executeFetch("GET", tagComplianceUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          setTagComplianceData({ data: response.data, isLoading: false });
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const tagComplianceTotalUrl =
      providerUrl +
      "/api/tagging_total?page_num=1&page_size=10000&" +
      urlParameters;
    executeFetch("GET", tagComplianceTotalUrl).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          if( response.data.length > 0 ) {
            setTagComplianceTotalData({
              score: response.data[0].score,
              resCount: response.data[0].resCount,
            });
          } else {
            setTagComplianceTotalData({
              score: 0,
              resCount: 0,
            });
          }
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          component="main"
          sx={{ flexGrow: 1, mx: "auto" }}
        >
          <BasicSnackbar
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={handleClose}
            vertical="top"
            horizontal="right"
          />
          <Typography></Typography>
          <DataGrid
            rows={tagComplianceData.data}
            rowCount={tagComplianceData.data.length}
            columns={columns}
            loading={tagComplianceData.isLoading}
            sx={userTableStyles}
            disableColumnSelector={true}
            slots={{
              toolbar: CustomToolbar,
            }}
            checkboxSelection
            hideFooterPagination
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const updatedResources = tagComplianceData.data.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectionModel(ids);
              setUpdatedResources(updatedResources);
            }}
            selectionModel={selectionModel}
          />
          <BulkAdd
            open={openTagging}
            onClose={() => setOpenTagging(false)}
            tagSet={tagSet}
            setTagSet={setTagSet}
            processChanges={processChanges}
            title={title}
            subTitle={subTitle}
            multiple={multiple}
            isAzure={true}
          />
          <TagConfirm
            open={openTagConfirm}
            onClose={() => setOpenTagConfirm(false)}
            updatedResources={updatedResources}
            applyChanges={applyChanges}
            title={title}
            subTitle={subTitle}
            expand={expand}
          />
          <TagApply
            open={openTagApply}
            onClose={() => setOpenTagApply(false)}
            tagSet={tagSet}
            setTagSet={setTagSet}
            title={title}
            subTitle={subTitle}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default TagDataTable;
