import { AppRoutes } from "./components/AppRoutes";
import { StickyNavbar } from "./components/StickyNavbar";
import { StickyFooter } from "./components/StickyFooter";
import { CollapsableSidebar } from "./components/CollapsableSidebar";
import { MsalAutoLogin } from "./components/MsalAutoLogin";
import { RefreshToken } from "./components/RefreshToken";
import { AuthProvider } from "./authContext";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { MapKey } from "./components/MapKey";
import { SessionDialog } from "./components/Modals/SessionDialog";
import { Paper, Typography, Container } from "@mui/material";

// eslint-disable-next-line react/prop-types
function App({ msalInstance }) {
  return (
    <AuthProvider>
      <MsalProvider instance={msalInstance}>
        <RefreshToken />
        <MsalAutoLogin />
        <MapKey />
        <BrowserRouter>
          <StickyNavbar />
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <div className="flex">
            <CollapsableSidebar />
            <div className="w-full p-4">
              <AppRoutes />
              <SessionDialog />
            </div>
          </div>
        </BrowserRouter>
        <StickyFooter />
      </MsalProvider>
    </AuthProvider>
    
  );
}

export default App;
