import React, { useEffect } from "react";
import lscache from "lscache";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";

export const MapKey = () => {
  const { ...rest } = useAuth();
  const cachedKey = lscache.get("mapKeyCached");
  const mapKeyUrl = process.env.REACT_APP_BACKEND_URI + "/api/map_key";

  useEffect(() => {
    if (!cachedKey) {
      if (rest.token) {
        executeFetch("GET", mapKeyUrl, rest.token).then((response) => {
          if (response) {
            if (response.isError) {
              console.log(response.error);
            } else {
              lscache.set("mapKeyCached", response.data.mapKey, 30);
              rest.setMapKey(response.data.mapKey);
            }
          }
        });
      }
    } else {
      rest.setMapKey(cachedKey);
    }
  }, [rest.token]);

  return;
};
