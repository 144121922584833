import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { modalStyles } from "../components/PopupModal/styles";
import { useForm, Controller } from "react-hook-form";
import executeFetch from "../helpers/executeFetch";

export default function TaxonomyModal({ ...rest }) {
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return rest.taxonomyData;
    }, [rest]),
    mode: "onTouched",
  });
  const [validationError, setValidationError] = useState({
    error: "",
  });
  const [submitData, setSubmitData] = useState({
    id: 0,
    tagName: "",
    aliases: "",
    description: "",
    isRequired: false,
    finOps: false,
    operations: false,
    patching: false,
  });

  useEffect(() => {
    const sub = watch((value) => {
      setValidationError({ error: "" });
    });
    return () => sub.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(rest.taxonomyData);
  }, [rest.taxonomyData]);

  useEffect(() => {}, [validationError]);

  useEffect(() => {
    if (submitData.id !== "") {
      rest.onClose();
      rest.saveData(submitData);
    }
  }, [executeFetch, submitData]);

  const onFormSubmit = ({
    id, 
    tagName,
    aliases,
    description,
    isRequired,
    finOps,
    operations,
    patching,
  }) => {
    setSubmitData({
      id:id,
      tagName: tagName,
      aliases: aliases,
      description: description,
      isRequired: isRequired === true ? "1" : "0",
      finOps: finOps  === true ? "1" : "0",
      operations: operations  === true ? "1" : "0",
      patching: patching  === true ? "1" : "0"
    });
  };

  // isRequired: state.isRequired === true ? 1 : 0,
  //     finOps: state.finOps === true ? 1 : 0,
  //     operations: state.operations === true ? 1 : 0,
  //     patching: state.patching === true ? 1 : 0,
  return (
    <Modal
      open={rest.open}
      onClose={rest.onClose}
    >
      <Box sx={modalStyles.wrapper}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            "& .MuiTextField-root": { m: 1, width: 500 },
            border: "2px solid black",
            p: 3,
          }}
        >
          <Typography variant="h6">Taxonomy Information</Typography>

          <TextField
            {...register("tagName", {
              required: true,
              pattern:
                /^[a-z-]*/,
            })}
            required
            label="Tag Name"
            variant="outlined"
            error={!!errors.tagName}
          />
          {errors.tagName &&
            errors.tagName.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
            )}
          {errors.tagName &&
            errors.tagName.type === "pattern" && (
              <Alert severity="error">Customer ID is not valid.</Alert>
            )}

          <TextField
            {...register("aliases", {
              required: true,
            })}
            required
            label="Aliases"
            variant="outlined"
            error={!!errors.aliases}
          />
          {errors.aliases &&
            errors.aliases.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
          )}
          <TextField
            {...register("description", {
              required: true,
            })}
            required
            label="Description"
            variant="outlined"
            error={!!errors.description}
          />
          {errors.description &&
            errors.description.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
          )}
         <label htmlFor=''>Required</label>
          <input
            type='checkbox'
            placeholder='isRequired'
            {...register('isRequired', {})}            
          />

          <label htmlFor=''>FinOps</label>
          <input
            type='checkbox'
            placeholder='finOps'
            {...register('finOps', {})}            
          />

          <label htmlFor=''>Operations</label>
          <input
            type='checkbox'
            placeholder='operations'
            {...register('operations', {})}            
          />
          
          <label htmlFor=''>Patching</label>
          <input
            type='checkbox'
            placeholder='patching'
            {...register('patching', {})}            
          />         
          {rest.taxonomyData.id === 0 ? (
            <Button
              variant="outlined"
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Add Taxonomy
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Update Taxonomy
            </Button>
          )}
          <Typography
            style={{ color: "red" }}
            variant="subtitle1"
          >
            {validationError.error}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
