import React, { useEffect, useState } from "react";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const MonthtoDateForecast = ({ onError }) => {
  const { ...rest } = useAuth();
  const [monthToDateForecast, setMonthToDateForecast] = useState({
    data: "0",
    isLoading: true,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    const MonthtoDateForecastUrl =
      providerUrl + "/api/subscription_forecast" + urlParameters;
    executeFetch("GET", MonthtoDateForecastUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          setMonthToDateForecast({ data: response.data, isLoading: false });
        }
      }
    });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />

<Card sx={{ minWidth: 250 }}>
      <CardHeader
      titleTypographyProps={{variant:'h6' }}
      title="Remaining Forecast"
      subheader={monthToDateForecast.data}
      >
      </CardHeader>
    </Card>
      </Box>
    </>
  );
};

export default MonthtoDateForecast;
