import { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Card,
  IconButton,
} from "@mui/material";
import { KeyboardDoubleArrowLeft, Menu, CloudQueue } from "@mui/icons-material";
import { useAuth } from "../authContext";
import { MenuAzure } from "./MenuAzure";
import { MenuGoogle } from "./MenuGoogle";
import { MenuAdmin } from "./MenuAdmin";
import { MenuTenant } from "./MenuTenant";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

export const CollapsableSidebar = () => {
  const [open, setOpen] = useState(true);
  const { ...rest } = useAuth();

  const handleSidebar = () => {
    setOpen(open === true ? false : true);
  };

  return (
    <div className={` flex-col bg-white  ${open ? "w-72" : "w-6"}`}>
      <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] shadow-xl">
        <div className={`flex ${open ? "content-end" : "content-start"}`}>
          <IconButton onClick={() => handleSidebar()}>
            {open ? <KeyboardDoubleArrowLeft /> : <Menu />}
          </IconButton>
        </div>
        {open ? (
          <List>
            <div className="hover:bg-primary-main hover:text-white">
              <ListItemButton href="/">
                <ListItemIcon>
                  <Tooltip title="Providers">
                    <CloudQueue />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Providers" />
              </ListItemButton>
            </div>
            {rest.provider ? (
              <>
                {rest.provider.provider === "Azure" ? <MenuAzure /> : null}
                {rest.provider.provider === "GCP" ? <MenuGoogle /> : null}
                {rest.isAdmin && rest.provider !== null ? <MenuTenant /> : null}
                {rest.isAdmin && rest.provider !== null ? <MenuAdmin /> : null}
              </>
            ) : null}
          </List>
        ) : null}
      </Card>
    </div>
  );
};
