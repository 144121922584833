import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const CustomerDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [customerData, setCustomerData] = useState({
    data: [],
    isLoading: true,
  });

  const handleClick = (event, cellValues) => {
    localStorage.setItem("customer", JSON.stringify(cellValues.row));
    rest.setCustomer(cellValues.row);
    window.location.reload();
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const columns = [
    { field: "customer_id", headerName: "Display Name", width: 300 },
    { field: "name", headerName: "customer Name", width: 300 },
    {
      field: " ",
      renderCell: (cellValues) => {
        if (cellValues.row.selected === "1") {
          return (
            <ThemeProvider theme={theme}>
            <Tooltip title={cellValues.row.customer_id}>
              <Typography
                variant="h6"
                color="primary"
              >
                Active
              </Typography>
            </Tooltip>
            </ThemeProvider>
          );
        } else {
          return (
            <ThemeProvider theme={theme}>
            <Tooltip title={cellValues.row.customer_id}>
              <Button
                variant="outlined"
                color="primary"
                sx={hoverStyles}
                onClick={(event) => {
                  handleClick(event, cellValues);
                }}
              >
                Switch
              </Button>
            </Tooltip>
            </ThemeProvider>
          );
        }
      },
      description: "Toggle active customer",
    },
  ];
  const userTableStyles = {
    height: "1000px",
  };
  const customersUrl = providerUrl + "/customers";

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    executeFetch("GET", customersUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          let data = response.data;
          if (data.length === 0) {
            rest.setCustomer("");
          } else {
            let index = 0;

            data.forEach((element) => {
              element.selected = "0";

              element.id = index;
              index = index + 1;
              if (rest.customer && rest.customer !== "") {
                if (element.customer_id === rest.customer.customer_id) {
                  element.selected = "1";
                }
              }
            });
            if (!rest.customer || rest.customer === "") {
              data.every((element) => {
                element.selected = "1";
                localStorage.setItem("customer", JSON.stringify(element));
                rest.setCustomer(element);
                window.location.reload();
                return false;
              });
            }
          }

          setCustomerData({ data: data, isLoading: false });
        }
      }
    });
  }, [executeFetch, rest.refeshToken]);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <DataGrid
          rows={customerData.data}
          rowCount={customerData.data.length}
          columns={columns}
          loading={customerData.isLoading}
          sx={userTableStyles}
          disableColumnSelector={true}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default CustomerDataTable;
