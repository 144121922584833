import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

export const LogoutButton = () => {
  const { instance } = useMsal();
  const signOutClickHandler = (instance) => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      mainWindowRedirectUri: "/",
      postLogoutRedirectUri: "/",
    };
    instance.logoutPopup(logoutRequest);
  };

  return (
    <div
      flex
      p1
    >
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          sx={hoverStyles}
          onClick={() => signOutClickHandler(instance)}
        >
          Sign Out
        </Button>
      </ThemeProvider>
    </div>
  );
};
