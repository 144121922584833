import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Admin from "@mui/icons-material/AdminPanelSettingsOutlined";
import Badge from '@mui/material/Badge';
import { v4 } from "uuid";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";
import { useAuth } from "../authContext";

export const MenuAdmin = () => {

  const { ...rest } = useAuth();
  const [mnissingSubscriptionData, setMissingSubscriptionData] = useState(0);

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const currentSubscriptionsUrl =
  providerUrl + "/api/subscriptions?cachebreaker=" + v4();
  const tenantSubscriptionsUrl =
  providerUrl +
  "/api/tenant_subscriptions?subscription=" +
  rest.subscription.subscription;

  const alertUrl =
  providerUrl +
  "/api/insert_alert";

  useEffect(() => {
  if(!tokenValid(rest.token)) {
    rest.setRefreshToken(true);
    return;
  }

  let hasRun = window.sessionStorage.getItem("hasRun");
  
  if (!hasRun && mnissingSubscriptionData <= 0 ) {
    executeFetch("GET", tenantSubscriptionsUrl, rest.token).then(
      (response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
          } else {
            let tenantSubscriptionsData = response.data.value;
            executeFetch("GET", currentSubscriptionsUrl, rest.token).then(
              (subresponse) => {
                if (subresponse) {
                  if (subresponse.isError) {
                    console.log(subresponse.error);
                  } else {
                    const currentSubscriptionsData = subresponse.data;

                    let mergedSubscriptionsData = [];
                    tenantSubscriptionsData.forEach((element) => {
                      element.exists = false;
                      for (
                        let i = 0;
                        i < currentSubscriptionsData.length;
                        i++
                      ) {
                        if (
                          element.subscriptionId ===
                          currentSubscriptionsData[i].subscription
                        ) {
                          element.exists = true;
                        }
                      }
                    });

                    tenantSubscriptionsData.forEach((elem) => {
                      if (elem.exists !== true)
                        mergedSubscriptionsData.push(elem);
                    });

                    if(mergedSubscriptionsData.length > 0) {

                        var payload = {
                            id: 0,
                            subscription: "",
                            status: 1,
                            alert_type: "Subscriptions",
                            alert_text: "Subscriptions Exists that are not tracked",
                            date_create: "",
                        }                        
                        console.log(payload);
                        executeFetch(
                          "POST",
                          alertUrl,
                          rest.token,
                          payload
                        ).then((response) => {
                          if (response) {
                            if (response.isError) {
                              console.log(response.error);
                            }
                          }
                        });
                      }
                    
                    setMissingSubscriptionData(mergedSubscriptionsData.length);
                    window.sessionStorage.setItem("hasRun", "true");
                    window.sessionStorage.setItem("missingSubscriptionData", mergedSubscriptionsData.length);
                    
                  }
                }
              }
            );
          }
        }
      }
    );
  }
  else {
    setMissingSubscriptionData(  window.sessionStorage.getItem("missingSubscriptionData"));
  }
  }, []);

    return (
      <div className="hover:bg-primary-main hover:text-white">
        <ListItemButton href="/admin">
          <ListItemIcon>
            <Tooltip title="Administration">
            <Badge badgeContent={mnissingSubscriptionData} color="error">
                <Admin />
              </Badge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Administration" />
        </ListItemButton>
      </div>
    );
  };

export default MenuAdmin;