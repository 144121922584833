import {
  AuthenticatedTemplate,
} from "@azure/msal-react";
import CostManagementTable from "../azure-components/Dashboard";
import GCPCostManagementTable from "../gcp-components/Dashboard";
import { useAuth } from "../authContext";

export const DashboardPage = () => {
  const { ...rest } = useAuth();
  return (
    <>
      <AuthenticatedTemplate>
        {rest.provider.provider === "Azure" ? <CostManagementTable /> : null}
        {rest.provider.provider === "GCP" ? <GCPCostManagementTable /> : null}
      </AuthenticatedTemplate>
    </>
  );
};
