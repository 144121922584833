import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import styled from "@mui/system/styled";
import { renderCellExpand } from "../components/RenderCellExpand";
import useLocalStorage from "../hooks/useLocalStorage";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  {
    field: "BlendCost",
    headerName: "Cost",
    width: 125,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return "$" + valueFormatted;
    },
  },
  { field: "EffectivePrice", headerName: "Effective Cost", width: 125 },
  {
    field: "UnitPrice",
    headerName: "Unit Price",
    width: 125,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value.toFixed(2)).toLocaleString();
      return "$" + valueFormatted;
    },
  },

  { field: "Quantity", headerName: "Quantity", width: 150 },
  {
    field: "Category",
    headerName: "Category",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    field: "Product",
    headerName: "Product",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "ResourceGroup",
    headerName: "Resource Group",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "Location",
    headerName: "Location",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "Name",
    headerName: "Resource Name",
    width: 250,
    renderCell: renderCellExpand,
  },
];

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "center",
}));

const userTableStyles = {
  height: "1000px",
};

const DecompositionDataTable = () => {
  const { ...rest } = useAuth();
  const [region, setRegion] = useLocalStorage("region_select", '');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  const [decompositionData, setDecompositionData] = useState({
    data: [],
    isLoading: true,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open: false});
  };

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }
  if (region !== '') {
    urlParameters = urlParameters + "&location=" + region.location;
    localStorage.removeItem("region_select");
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const summaryDecompositionUrl =
      providerUrl +
      "/api/decomposition" +
      urlParameters;
    executeFetch("GET", summaryDecompositionUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          setDecompositionData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Grid container spacing={0}>
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <Item>
              <Typography variant="h4">Decomposition</Typography>
            </Item>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <DataGrid
          rows={decompositionData.data}
          rowCount={decompositionData.data.total}
          columns={columns}
          loading={decompositionData.isLoading}
          sx={userTableStyles}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default DecompositionDataTable;
