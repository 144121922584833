import React from "react";
import { Dialog, Box, CircularProgress, DialogTitle } from "@mui/material";

const Inventory = ({ open }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Dialog open={open}>
        <DialogTitle>{"Querying Refreshed Tagging Information"}</DialogTitle>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
        <br />
      </Dialog>
    </Box>
  );
};

export default Inventory;
