import React, { useEffect, useState } from "react";
import { PieChart } from '@mui/x-charts/PieChart';

import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';

import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";
import Grid from "@mui/system/Unstable_Grid";
import { renderCellExpand } from "../components/RenderCellExpand";

const userTableStyles = {
  height: "1000px",
};

const TagResourceCountsTable = ({ onError }) => {
  const { ...rest } = useAuth();
  //const [startDate, setStartDate] = useState('');
  //const [endDate, setEndDate] = useState('');
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [tagParam, settagParam] = React.useState();

  const [resourceByTagData, setResourceByTagData] = useState({
    data: [],
    isLoading: true
  })

  const [resourceNoTagData, setResourceNoTagData] = useState({
    data: [],
    isLoading: true
  })

  const [taggedCountData, settaggedCountData] = useState({
    data: [],
    isLoading: true
  })

  const [untaggedCountData, setuntaggedCountData] = useState({
    data: [],
    isLoading: true
  })

  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open: false});
  };

  let urlParameters = "";
  let urlSubscription = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
    urlSubscription = rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  let taggedCountUrl =
    providerUrl +
    "/api/count_tag_rpt" +
    urlParameters;

  let untaggedCountUrl =
    providerUrl +
    "/api/count_untagged_rpt" +
    urlParameters;


  let resourcesByTagUrl =
    providerUrl +
    "/api/resource_by_tag_rpt" +
    urlParameters;
    
  let resourcesNoTagUrl =
    providerUrl +
    "/api/resource_no_tag_rpt" +
    urlParameters;

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }

    executeFetch("GET", taggedCountUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          settaggedCountData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    rest.refreshToken
  ]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }

    executeFetch("GET", untaggedCountUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setuntaggedCountData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    rest.refreshToken
  ]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }

    resourcesByTagUrl = resourcesByTagUrl + '&tagName=' + tagParam

    executeFetch("GET", resourcesByTagUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setResourceByTagData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    rest.refreshToken, tagParam
  ]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }

    resourcesNoTagUrl = resourcesNoTagUrl + '&tagName=' + tagParam

    executeFetch("GET", resourcesNoTagUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setResourceNoTagData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    rest.refreshToken, tagParam
  ]);
  

  const handleClick = (event, itemIdentifier, item) => {
    console.log(item);
    settagParam(item.label);
  };
  const [itemData, setItemData] = React.useState();

  const columns = [
    {
      field: "resName",
      headerName: "Resource Name",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      field: "resType",
      headerName: "Resource Type",
      width: 300,
      renderCell: renderCellExpand,
    },
    {
      field: "resGroup",
      headerName: "Resource Group",
      width: 200,
      renderCell: renderCellExpand,
    }    
  ];

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto", pl: "10px" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        
        <br/>

        <Grid
          container
          spacing={0}>
          <Grid xs={6} height={50}>
            <Typography variant="h5">
              <center>Resource Count with Tag Name</center>
            </Typography>
          </Grid>
          <Grid xs={6} height={50}>
            <Typography variant="h5">
              <center>Resources with {tagParam} Tag</center>
            </Typography>
          </Grid>
        
          <Grid xs={6} >
          {taggedCountData.isLoading ? null :
            <PieChart
                colors={mangoFusionPalette}
                series={[
                  {
                    data: taggedCountData.data,
                  },
                ]}
                width={700}
                height={400}
                onItemClick={handleClick}
                //onItemClick={(event, d) => setItemData(d)}
              />
            }     
        </Grid>     
        <Grid xs={6} >
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={resourceByTagData.data}
          rowCount={resourceByTagData.data.length}
          columns={columns}
          loading={resourceByTagData.isLoading}
          disableColumnSelector={true}
        />
          </div>
        </Grid>   
      </Grid>




<br></br>






      <Grid
          container
          spacing={0}>
          <Grid xs={6} height={50}>
            <Typography variant="h5">
            <center>Resource Count without Tag Name</center>
            </Typography>
          </Grid>
          <Grid xs={6} height={50}>
            <Typography variant="h5">
              <center>Resource without {tagParam} Tag</center>
            </Typography>
          </Grid>
        
          <Grid xs={6} >
            {untaggedCountData.isLoading ? null :
            <PieChart
                colors={cheerfulFiestaPalette} // Use palette
                series={[
                  {
                    data: untaggedCountData.data,
                  },
                ]}
                width={750}
                height={400}
                onItemClick={handleClick}
              />
              }
          </Grid>     
        <Grid xs={6} >
            
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={resourceNoTagData.data}
          rowCount={resourceNoTagData.data.length}
          columns={columns}
          loading={resourceNoTagData.isLoading}
          disableColumnSelector={true}
        />
          </div>
              
        </Grid>   
      </Grid>



    </Box>
    </>
  );
};

export default TagResourceCountsTable;
