export const modalStyles = {
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  inputFields: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    ".MuiInput-root": {
      marginBottom: "10px",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
  },
};
