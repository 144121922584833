import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import { Checkbox, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { v4 } from "uuid";
import ProviderModal from "./Modals/ProviderModal";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

const userTableStyles = {
  height: "1000px",
};

const AdminProviderDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [showProviderModal, setShowProviderModal] = useState(false);
  const [providersData, setProvidersData] = useState({
    data: [],
    isLoading: true,
  });
  const [providerData, setProviderData] = useState({
    id: 0,
    friendly_name: "",
    organization_tenant: "",
    url: "",
    provider: "",
    isManaged: false,
  });
  const [refreshProviderData, setRefreshProviderData] = useState(true);
  const [updateProviderData, setUpdateProviderData] = useState({
    url: "",
    method: "",
    payload: null,
    update: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const saveProviderData = (data) => {
    console.log(data);
    let providerCrudUrl = "";
    let method = "";
    if (data.id === 0) {
      providerCrudUrl = process.env.REACT_APP_BACKEND_URI + "/api/add_provider";
      method = "POST";
    } else {
      providerCrudUrl =
        process.env.REACT_APP_BACKEND_URI + "/api/update_provider/" + data.id;
      method = "PUT";
    }

    setUpdateProviderData({
      url: providerCrudUrl,
      method: method,
      payload: data,
      update: true,
    });
  };

  useEffect(() => {
    if (refreshProviderData) {
      const providersUrl =
        process.env.REACT_APP_BACKEND_URI +
        "/api/providers?cachebreaker=" +
        v4();
      executeFetch("GET", providersUrl, rest.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } else {
            let data = response.data;
            if (rest.provider !== "") {
              data.forEach((element) => {
                if (element.provider === rest.provider.provider) {
                  element.active = "1";
                }
              });
            } else {
              data.every((element) => {
                element.active = "1";
                return false;
              });
            }
            setProvidersData({ data: data, isLoading: false });
            setRefreshProviderData(false);
          }
        }
      });
    }
  }, [executeFetch, refreshProviderData]);

  useEffect(() => {
    if (updateProviderData.update) {
      executeFetch(
        updateProviderData.method,
        updateProviderData.url,
        updateProviderData.payload
      ).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          }
        }
      });
      setProvidersData({
        data: [],
        isLoading: true,
      });
      setUpdateProviderData({
        method: "",
        url: "",
        payload: null,
        update: false,
      });
      setRefreshProviderData(true);
    }
  }, [executeFetch, updateProviderData]);

  const providerUrl = process.env.REACT_APP_BACKEND_URI + "/api/providers";

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 20,
      renderCell: (cellValues) => {
        return (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              onClick={(event) => {
                handleRowEditClick(event, cellValues);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: "friendly_name",
      headerName: "Provider Name",
      width: 200,
      editable: false,
    },
    {
      field: "organization_tenant",
      headerName: "Organization OR Tenant",
      width: 300,
      editable: false,
    },
    { field: "url", headerName: "Backend URL", width: 500, editable: false },
    { field: "provider", headerName: "Provider", width: 200, editable: false },
    {
      field: "ismanaged",
      headerName: "Use Managed Identity",
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Checkbox
            aria-label="managed"
            disabled={true}
            checked={cellValues.row.ismanaged}
          ></Checkbox>
        );
      },
    },
    {
      field: " ",
      headerName: "Delete",
      width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              handleDeleteClick(event, cellValues);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      description: "Delete provider",
    },
  ];

  const addProvider = () => {
    setShowProviderModal(true);
  };

  const handleRowEditClick = (event, cellValues) => {
    setProviderData({
      id: cellValues.row.id,
      friendly_name: cellValues.row.friendly_name,
      organization_tenant: cellValues.row.organization_tenant,
      url: cellValues.row.url,
      provider: cellValues.row.provider,
      isManaged: cellValues.row.isManaged,
    });
    setShowProviderModal(true);
  };

  const handleDeleteClick = (event, cellValues) => {
    localStorage.setItem("provider", JSON.stringify(cellValues.row));

    event.preventDefault();

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this provider?"
    );

    if (isConfirmed) {
      const providerCrudUrl =
        process.env.REACT_APP_BACKEND_URI +
        "/api/delete_provider/" +
        cellValues.row.id;
      setUpdateProviderData({
        url: providerCrudUrl,
        method: "DELETE",
        payload: null,
        update: true,
      });
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          component="main"
          sx={{ flexGrow: 1, mx: "auto" }}
        >
          <Button
            variant="outlined"
            sx={hoverStyles}
            color="primary"
            onClick={addProvider}
          >
            Add Provider
          </Button>

          <BasicSnackbar
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={handleClose}
            vertical="top"
            horizontal="right"
          />
          <DataGrid
            rows={providersData.data}
            rowCount={providersData.data.length}
            columns={columns}
            loading={providersData.isLoading}
            sx={userTableStyles}
            disableColumnSelector={true}
            disableMpultipleSelection={true}
            hideFooterPagination
          />
          <ProviderModal
            open={showProviderModal}
            onClose={() => setShowProviderModal(false)}
            saveData={saveProviderData}
            providerData={providerData}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdminProviderDataTable;
