import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import { Button, Checkbox, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";
import TaxonomyModel from "./TaxonomyModel";

const userTableStyles = {
  height: "1000px",
};

const AdminGCPTaxonomyDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [showTaxonomyModal, setShowTaxonomyModal] = useState(false);
  const [taxonomysData, setTaxonomysData] = useState({
    data: [],
    isLoading: true,
  });
  // State object, properties and validation methods used for editing existing taxonomy...
  const [taxonomyData, setTaxonomyData] = useState({
    id: -1,
    tagName: "",
    aliases: "",
    description: "",
    isRequired: false,
    finOps: false,
    operations: false,
    patching: false,
    isValid: false,
  });
  const [refreshTaxonomyData, setRefreshTaxonomyData] = useState(true);
  const [updateTaxonomyData, setUpdateTaxonomyData] = useState({
    url: "",
    method: "",
    payload: null,
    update: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // const taxonomyUrl = process.env.REACT_APP_BACKEND_URI;
  // const taxonomyInsertUrl = taxonomyUrl + "/api/add_taxonomy";
  // const taxonomyDeleteUrl = taxonomyUrl + "/api/delete_taxonomy";

  const saveTaxonomyData = (data) => {
    console.log(data);
    let taxonomyCrudUrl = "";
    let method = "";
    if( data.id >= 0 ) {

      if (data.id === 0) {
        taxonomyCrudUrl = process.env.REACT_APP_BACKEND_URI + "/api/add_taxonomy";
        method = "POST";
      } else {
        taxonomyCrudUrl = process.env.REACT_APP_BACKEND_URI + "/api/update_taxonomy/" + data.id;

        method = "PUT";
      }
      setUpdateTaxonomyData({
        url: taxonomyCrudUrl,
        method: method,
        payload: data,
        update: true,
      });
    }
  };

  let providerUrl = "";
  if (rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (refreshTaxonomyData) {
      const taxonomysUrl = process.env.REACT_APP_BACKEND_URI + "/api/taxonomy";
      executeFetch("GET", taxonomysUrl, rest.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } 
          
          let data = response.data;          
          setTaxonomysData({ data: data, isLoading: false });
          setRefreshTaxonomyData(false);
        }
      });
    }
  }, [executeFetch, refreshTaxonomyData, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (updateTaxonomyData.update) {
      executeFetch(
        updateTaxonomyData.method,
        updateTaxonomyData.url,
        rest.token,
        updateTaxonomyData.payload
      ).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          }
        }
      });
      setTaxonomysData({
        data: [],
        isLoading: true,
      });
      setUpdateTaxonomyData({
        method: "",
        url: "",
        payload: null,
        update: false,
      });
      setRefreshTaxonomyData(true);
    }
  }, [executeFetch, updateTaxonomyData, rest.refreshToken]);

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 20,
      renderCell: (cellValues) => {
        return (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              onClick={(event) => {
                handleRowEditClick(event, cellValues);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
      disableClickEventBubbling: true,
    },
    { field: "tagName", headerName: "Tag Name", width: 150, editable: false },
    { field: "customer", headerName: "Customer", width: 300, editable: false },
    { field: "aliases", headerName: "Aliases", width: 300, editable: false },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      editable: false,
    },
    { field: "isRequired", hide: true },
    { field: "finOps", hide: true },
    { field: "operations", hide: true },
    { field: "patching", hide: true },
    { field: "isDirty", hide: true },
    {
      field: "requiredCheck",
      headerName: "Required",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Checkbox
            aria-label="save"
            disabled={true}
            checked={cellValues.row.isRequired === 1 ? true : false}
          ></Checkbox>
        );
      },
    },
    {
      field: "finOpsCheck",
      headerName: "FinOps",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Checkbox
            aria-label="save"
            disabled={true}
            checked={cellValues.row.finOps === 1 ? true : false}
          ></Checkbox>
        );
      },
    },
    {
      field: "operationsCheck",
      headerName: "Operations",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Checkbox
            aria-label="save"
            disabled={true}
            checked={cellValues.row.operations === 1 ? true : false}
          ></Checkbox>
        );
      },
    },
    {
      field: "patchingCheck",
      headerName: "Patching",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Checkbox
            aria-label="save"
            disabled={true}
            checked={cellValues.row.patching === 1 ? true : false}
          ></Checkbox>
        );
      },
    },
    {
      field: " ",
      headerName: "Delete",
      width: 60,
      Tooltip: "Delete",
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              handleDeleteClick(event, cellValues);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      description: "Delete Taxonomy",
    },
  ];

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this taxonomy?"
    );
    if (isConfirmed) {
      const taxonomyCrudUrl = process.env.REACT_APP_BACKEND_URI + "/api/delete_taxonomy/" + cellValues.row.id;
      setUpdateTaxonomyData({
        url: taxonomyCrudUrl,
        method: "DELETE",
        payload: null,
        update: true,
      });
    }
  };

  const addApplication = () => {
    setTaxonomyData({
      id: 0,
      tagName: "",
      aliases: "",
      description: "",
      isRequired: false,
      finOps: false,
      operations: false,
      patching: false,
    });
    setShowTaxonomyModal(true);
  };

  const handleRowEditClick = (event, cellValues) => {
    setTaxonomyData({
      id: cellValues.row.id,
      customer: rest.customer.customer_id,
      tagName: cellValues.row.tagName,
      aliases: cellValues.row.aliases,
      description: cellValues.row.description,
      isRequired: cellValues.row.isRequired === 1 ? true : false,
      finOps: cellValues.row.finOps === 1 ? true : false,
      operations: cellValues.row.operations === 1 ? true : false,
      patching: cellValues.row.patching === 1 ? true : false,
      isValid: true,
    });
    setShowTaxonomyModal(true);
  };

  return (
    <>
      <div>
        <ThemeProvider theme={theme}>
        <Button
            variant="outlined"
            sx={hoverStyles}
            color="primary"
            onClick={addApplication}
          >
            Add Taxonomy
          </Button>

          <Box
            component="main"
            sx={{ flexGrow: 1, mx: "auto" }}
          >
            <BasicSnackbar
              open={snackbar.open}
              severity={snackbar.severity}
              message={snackbar.message}
              onClose={handleClose}
              vertical="top"
              horizontal="right"
            />
            <DataGrid
              rows={taxonomysData.data}
              rowCount={taxonomysData.data.length}
              columns={columns}
              loading={taxonomysData.loading}
              sx={userTableStyles}              
              disableColumnSelector={true}
              disableMultipleSelection={true}
              columnVisibilityModel={{
                id: false,
                isRequired: false,
                finOps: false,
                operations: false,
                patching: false,
                isDirty: false,
              }}
            />

            <TaxonomyModel
              open={showTaxonomyModal}
              onClose={() => setShowTaxonomyModal(false)}
              saveData={saveTaxonomyData}
              taxonomyData={taxonomyData}
            />

          </Box>           
        </ThemeProvider>
      </div>
    </>
  );
};

export default AdminGCPTaxonomyDataTable;
