import { jwtDecode } from "jwt-decode";

export const tokenRefresh = (token) => {
  if (token !== null) {
    const expiry = jwtDecode(token).exp - 60 * 5;
    const now = Date.now() / 1000;
    return now > expiry;
  } else {
    return false;
  }
}
