import { useEffect } from "react";
import { appRoles } from "../authConfig";
import {
  useMsal,
  useMsalAuthentication,
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { tokenRefresh } from "../helpers/tokenRefresh.js";

import { useAuth } from "../authContext";

export const MsalAutoLogin = () => {
  const { ...rest } = useAuth();
  const { login, error } = useMsalAuthentication(InteractionType.Popup, {
    scopes: [process.env.REACT_APP_APP_SCOPE],
  });
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (
      (!isAuthenticated || tokenRefresh(rest.token)) &&
      inProgress === InteractionStatus.None
    ) {
      instance
        .ssoSilent({
          scopes: [process.env.REACT_APP_APP_SCOPE],
        })
        .then((response) => {
          instance.setActiveAccount(response.account);
          rest.setRoles(response.account?.idTokenClaims["roles"]);
          rest.setUsername(response.username);
          rest.setName(response.name);
          rest.setToken(response.idToken);
          rest.setIsAdmin(
            response.account?.idTokenClaims["roles"].includes(appRoles.Admins)
          );
          rest.setIsAuthenticated(true);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            console.log("Silent login failed - Interactive required");
            instance
              .loginPopup({
                scopes: [process.env.REACT_APP_APP_SCOPE],
              })
              .then((response) => {
                instance.setActiveAccount(response.account);
                rest.setRoles(response.account?.idTokenClaims["roles"]);
                rest.setUsername(response.username);
                rest.setName(response.name);
                rest.setToken(response.idToken);
                rest.setIsAdmin(
                  response.account?.idTokenClaims["roles"].includes(
                    appRoles.Admins
                  )
                );
                rest.setIsAuthenticated(true);
              });
          }
        });
    } else {
      const currentAccount = instance.getActiveAccount();
      if (currentAccount) {
        rest.setName(currentAccount.name);
        rest.setUsername(currentAccount.username);
        rest.setToken(currentAccount.idToken);
        if (currentAccount.idTokenClaims["roles"]) {
          rest.setRoles(currentAccount.idTokenClaims["roles"]);
          rest.setIsAdmin(
            currentAccount.idTokenClaims["roles"].includes(appRoles.Admins)
          );
        }
        rest.setIsAuthenticated(true);
      }
    }
    rest.setRefreshToken(false);
  }, [instance, isAuthenticated, rest.refreshToken]);

  return;
};
