import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import BasicSnackbar from "../components/BasicSnackbar";
import { renderCellExpand } from "../components/RenderCellExpand";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  {
    field: "customer_id",
    headerName: "Customer",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "organization_id",
    headerName: "Organization",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "resource_type",
    headerName: "Resource Type",
    width: 300,
    renderCell: renderCellExpand,
  },
  // { field: 'resource_id', headerName: 'Resource Identifier', width: 550, renderCell: renderCellExpand },
  {
    field: "resource_name",
    headerName: "Resource Group",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "resource_location",
    headerName: "Resource Location",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "labels",
    headerName: "Labels",
    width: 400,
    renderCell: renderCellExpand,
    valueGetter: (params) => {
      console.log({ params });
      let result = [];
      if (params.row.labels) {
        result.push(JSON.stringify(params.row.labels));
      }
      return result.join(", ");
    },
  },

  // { field: 'labels', headerName: 'Tags', width: 300, renderCell: renderCellExpand },
];
const userTableStyles = {
  height: "1000px",
};

const TagDataTable = ({ isWriter }) => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url + "/customers/";
  }

  const tagComplianceUrl =
    providerUrl + rest.customer.customer_id + "/query/resources";

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: "flex" }}>
        <GridToolbarQuickFilter sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
        <GridToolbarExport
          variant="contained"
          sx={{ mr: 2, pt: 0.8, pb: 0.8 }}
        />
        <Typography variant="h6">
          Last Tag Inventory: {tagComplianceData.length ? tagComplianceData.data[0].date : null}{" "}
          UTC
        </Typography>
        <GridToolbarDensitySelector sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
      </GridToolbarContainer>
    );
  }

  const [tagComplianceData, setTagComplianceData] = useState({
    data: [],
    isLoading: true,
  });

  const handleCellClick = (param, event) => {};

  const handleRowClick = (param, event) => {};

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open:false});
  };

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    executeFetch("GET", tagComplianceUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          setTagComplianceData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Typography></Typography>
        <DataGrid
          rows={tagComplianceData.data}
          rowCount={tagComplianceData.data.length}
          columns={columns}
          loading={tagComplianceData.isLoading}
          sx={userTableStyles}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          disableColumnSelector={true}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Box>
    </>
  );
};

export default TagDataTable;
