import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { modalStyles } from "../PopupModal/styles";
import { useForm } from "react-hook-form";
import { FormInputRadio } from "../FormInputRadio";
import { useAuth } from "../../authContext";
import executeFetch from "../../helpers/executeFetch";

export default function ProviderModal({ ...rest }) {
  const { ...restModal } = useAuth();
  const {
    register,
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return rest.providerData;
    }, [rest]),
    mode: "onTouched",
  });

  const options = [
    {
      label: "Microsoft Azure",
      value: "Azure",
    },
    {
      label: "Google Cloud Platform",
      value: "GCP",
    },
  ];

  const [submitData, setSubmitData] = useState({
    id: 0,
    friendly_name: "",
    organization_tenant: "",
    url: "",
    provider: "",
    isManaged: false,
  });
  const [validationError, setValidationError] = useState({
    error: "",
  });

  useEffect(() => {
    reset(rest.providerData);
  }, [rest.providerData]);

  const onFormSubmit = ({
    id,
    friendly_name,
    organization_tenant,
    url,
    provider,
    isManaged,
  }) => {
    setSubmitData({
      id: id,
      friendly_name: friendly_name,
      organization_tenant: organization_tenant,
      url: url,
      provider: provider,
      isManaged: false,
    });
  };

  useEffect(() => {
    const sub = watch((value) => {
      setValidationError({error: ""});
    });
    return () => sub.unsubscribe();
  }, [watch])

  useEffect(() => {
  }, [validationError.error]);

  useEffect(() => {
    if (submitData.organization_tenant !== "") {
      console.log(submitData);

      setValidationError({error: "Querying backend URL..."})

      let backendTestUrl = submitData.url;
      if (submitData.provider === "Azure") {
        backendTestUrl += "/api/subscriptions";
      }
      if (submitData.provider === "GCP") {
        backendTestUrl += "/customers";
      }

      executeFetch("GET", backendTestUrl, restModal.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response);
            let re = /^net::Connection Failed/;
            if(re.exec(response.error)) {
              setValidationError({ error: "Cannot communicate with backend URL" });
            }
            re = /404/;
            if(re.exec(response.error)) {
              setValidationError({ error: "Improper backend URL for Cloud Provider" });
            }
            re = /403/;
            if(re.exec(response.error)) {
              setValidationError({ error: "Backend authentication failed.  Check configuration." });
            }

          } else {
            rest.onClose();
            rest.saveData(submitData);
          }
        }
      });
    }
  }, [submitData]);

  return (
    <Modal
      open={rest.open}
      onClose={rest.onClose}
    >
      <Box sx={modalStyles.wrapper}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            "& .MuiTextField-root": { m: 1, width: 500 },
            border: "2px solid black",
            p: 3,
          }}
        >
          <Typography variant="h6">
            Cloude Provider Backend Information
          </Typography>

          <TextField
            {...register("friendly_name", { required: true })}
            required
            label="Provider Name"
            variant="outlined"
            error={!!errors.friendly_name}
          />
          {errors.friendly_name && errors.friendly_name.type === "required" && (
            <Alert severity="error">This field is required</Alert>
          )}

          <TextField
            {...register("organization_tenant", {
              required: true,
            })}
            required
            label="Organization OR Tenant ID"
            variant="outlined"
            error={!!errors.organization_tenant}
          />
          {errors.organization_tenant &&
            errors.organization_tenant.type === "required" && (
              <Alert severity="error">This field is required.</Alert>
            )}

          <TextField
            {...register("url", {
              required: true,
              pattern:
              /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/i
            })}
            required
            label="Backend URL"
            variant="outlined"
            error={!!errors.url}
          />
          {errors.url && errors.url.type === "required" && (
            <Alert severity="error">This field is required.</Alert>
          )}
          {errors.url && errors.url.type === "pattern" && (
            <Alert severity="error">Backend is not a valid URL.</Alert>
          )}
          <Typography variant="h6">Cloud Provider</Typography>
          <FormInputRadio
            {...register("provider", {
              required: true,
            })}
            name={"provider"}
            control={control}
            label={""}
            required
            error={!!errors.provider}
            options={options}
          />
          {errors.provider && errors.provider.type === "required" && (
            <Alert severity="error">Provider not selected.</Alert>
          )}

          {rest.providerData.id === 0 ? (
            <Button
              variant="contained"
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Add Provider
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit(onFormSubmit)}
              label="submit"
            >
              Update Provider
            </Button>
          )}
          <Typography
                style={{ color: "red" }}
                variant="subtitle1"
              >
                {validationError.error}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
