import React, { useEffect, useState } from "react";
import BasicSnackbar from "../components/BasicSnackbar";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { tokenValid } from "../helpers/tokenValid";
import Typography from '@mui/material/Typography';
import Grid from "@mui/system/Unstable_Grid";

import { PieChart } from '@mui/x-charts/PieChart';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';

const CurrentTagData = ({ onError }) => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters =
      urlParameters + "?subscription=" + rest.subscription.subscription;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  const [taggedCountData, settaggedCountData] = useState({
    data: [],
    isLoading: true
  })

  const [untaggedCountData, setuntaggedCountData] = useState({
    data: [],
    isLoading: true
  })

  let taggedCountUrl =
  providerUrl +
  "/api/count_tag_rpt" +
  urlParameters;

let untaggedCountUrl =
  providerUrl +
  "/api/count_untagged_rpt" +
  urlParameters;

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }

    executeFetch("GET", taggedCountUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          settaggedCountData({data: response.data, isLoading: false});
        }
      }
    })
  }, [
    rest.refreshToken
  ]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }

    executeFetch("GET", untaggedCountUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else {
          setuntaggedCountData({data: response.data, isLoading: false});
        }
      }
    })
    //}
  }, [
    rest.refreshToken
  ]);

  return (
    <>
      <BasicSnackbar
        open={snackbar.open}
        severity={snackbar.severity}
        message={snackbar.message}
        onClose={handleClose}
        vertical="top"
        horizontal="right"
      />
       <Grid
          container
          spacing={0}>
            <Grid xs={12} >
            <Typography variant="h5"
              align="center"
            >
              <b> Tag Insights </b>
            </Typography>

            </Grid>
            <Grid xs={6} >
            <Card>

            <CardHeader
      
              title="Resource Count with Tag Name"
              // subheader="Current Month"
              >
      
            </CardHeader>
          <CardContent align="left">
        
            {taggedCountData.isLoading ? null :
              <PieChart margin={{ top: 10, bottom: 10, left: 10, right:250 }}
              series={[
                {
                  data: taggedCountData.data,
                },
              ]}
              slotProps={{
                legend: {
                  direction: 'column',
                  markGap: 5,
                  itemGap: 5,
                  labelStyle: {
                    fontSize: 11,
                  },
                }
              }}
              width={500}
              height={200}
                
              />
            }
          </CardContent>
        </Card>
        </Grid>

        <Grid xs={6} >
            <Card>

            <CardHeader
      
              title="Resource Count without Tag Name"
              // subheader="Current Month"
              >
      
            </CardHeader>
          <CardContent>
        
            {untaggedCountData.isLoading ? null :
              <PieChart margin={{ top: 10, bottom: 10, left: 10, right:250 }}
              colors={mangoFusionPalette}
              series={[
                {
                  data: untaggedCountData.data,
                },
              ]}
              slotProps={{
                legend: {
                  direction: 'column',
                  markGap: 5,
                  itemGap: 5,
                  labelStyle: {
                    fontSize: 11,
                  },
                }
              }}
              width={500}
              height={200}
                
              />
            }
          </CardContent>
        </Card>
        </Grid>
      </Grid>

    </>
  );
};

export default CurrentTagData;
