import React from "react";
import { useTheme, Paper, Typography, Container } from "@mui/material";

export const StickyFooter = () => {
  const theme = useTheme();

  return (
      <Paper
        square
        style={{
          padding: theme.spacing(2),
          // marginTop: "auto",
          // backgroundColor: "lightyellow",
          
          position: "fixed",
          width: "100%",
          bottom: 0,
          color: "white",
          fontSize: "25px",
          textAlign: "center",
        }}
      >
        {/* Your footer content goes here */}
        <Typography variant="body2" color="red" align="right">
        Version {process.env.REACT_APP_VERSION}
        </Typography>
      </Paper>
  );
};