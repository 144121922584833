import DecompositionData from "../azure-components/DecompositionData";
import GCPDecompositionData from "../gcp-components/GCPDecompositionData";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useAuth } from "../authContext";

export function DecompPage() {
  const { ...rest } = useAuth();
  return (
    <>
      <AuthenticatedTemplate>
        {rest.provider.provider === "Azure" ? <DecompositionData /> : null}
        {rest.provider.provider === "GCP" ? <GCPDecompositionData /> : null}
      </AuthenticatedTemplate>
    </>
  );
}
