import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Typography, Button } from "@mui/material";
import BasicSnackbar from "../components/BasicSnackbar";
import BulkAdd from "../components/Modals/BulkAdd";
import TagConfirm from "../components/Modals/TagConfirm";
import TagApply from "../components/Modals/TagApply";
import Inventory from "../components/Modals/Inventory";
import { renderCellExpand } from "../components/RenderCellExpand";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  {
    field: "asset",
    headerName: "Asset",
    width: 350,
    renderCell: renderCellExpand,
  },
  {
    field: "project",
    headerName: "Project",
    width: 150,
    renderCell: renderCellExpand,
  },
  // { field: "asset_type", hide: true },
  {
    field: "service",
    headerName: "Service",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: "resource_type",
    headerName: "Type",
    width: 130,
    renderCell: renderCellExpand,
  },
  // {
  //   field: "countLabels",
  //   headerName: "Compliance Score",
  //   width: 200,
  //   renderCell: renderCellExpand,
  // },
  {
    field: "countLabels",
    headerName: "Label Count",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    field: "renderTags",
    headerName: "Labels",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "missingLabelCount",
    headerName: "Missing Count",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    field: "missingLabels",
    headerName: "Missing Labels",
    width: 300,
    renderCell: renderCellExpand,
  },

  // { field: 'resource_id', headerName: 'Resource Identifier', width: 550, renderCell: renderCellExpand },
  //{ field: "zone", headerName: "Resource Location", width: 150 },
];
const userTableStyles = {
  height: "1000px",
};

const setLabelResources = [
  "ai platform",
  "appengine",
  "artifactregistry",
  "bigquery",
  "certificate authority service",
  "bigtable",
  "composer",
  "deploymentmanager",
  "functions",
  "healthcareapi",
  "keymanagementservice",
  "run",
  "spanner",
  "sql",
  "storage",
  "translation",
  "workstations",
  "compute engine",
  "dataflow",
  "dataproc",
  "filestore",
  "googlekubernetesengine",
  "memorystore",
  "networking",
  "pubsub",
  "recaptcha enterprise",
  "resourcemanager",
  "transcoder api",
  "vertex ai",
  "workflows",
];

const GCPTagDataTable = () => {
  const { ...rest } = useAuth();
  const [openTagging, setOpenTagging] = useState(false);
  const [openTagConfirm, setOpenTagConfirm] = useState(false);
  const [openTagApply, setOpenTagApply] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);
  const [expand, setExpand] = useState(false);
  const [enableTagUpdate, setEnableTagUpdate] = useState(true);
  const [processTags, setProcessTags] = useState(false);
  const [multiple, setMultiple] = useState(false);

  const [selectionModel, setSelectionModel] = useState([]);
  const [updatedResources, setUpdatedResources] = useState([]);
  const [tagSet, setTagSet] = useState([]);
  const [tagTaxonomySet, setTagTaxonomySet] = useState([]);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url + "/customers/";
  }

  const tagComplianceUrl =
    providerUrl + rest.customer.customer_id + "/query/resources/labellable";
  const tagUpdateUrl =
    providerUrl + rest.customer.customer_id + "/bulk-set-labels";
  const tagTaxonomyUrl =
    process.env.REACT_APP_BACKEND_URI +
    "/api/taxonomy?customer=" +
    rest.customer.customer_id;

  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 50,
    totalResources: 0,
    totalCompliance: "",
    loading: true,
  });

  function UpdateTagsButton() {
    return (
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          sx={hoverStyles}
          disabled={!updatedResources.length || !enableTagUpdate}
          onClick={(event) => {
            setOpenTagging(true);
            handleTaggingUpdate(updatedResources, event);
          }}
        >
          Update Labels
        </Button>
      </ThemeProvider>
    );
  }

  function CustomToolbar() {
    return (
      <ThemeProvider theme={theme}>
        <GridToolbarContainer style={{ display: "flex" }}>
          <GridToolbarQuickFilter sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
          <Typography
            variant="h6"
            sx={{ mr: 2 }}
          >
            Total Compliance Score: {pageState.totalCompliance}
          </Typography>
          <Typography variant="h6">
            Labellable Resources: {pageState.totalResources}
          </Typography>
          <GridToolbarExport
            variant="outlined"
            sx={{
              mr: 2,
              pt: 0.8,
              pb: 0.8,
              "&:hover": {
                color: "#fff",
                backgroundColor: "primary.main",
              },
            }}
          />
          <UpdateTagsButton />
          <GridToolbarDensitySelector sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
        </GridToolbarContainer>
      </ThemeProvider>
    );
  }

  const mergeTagTaxonomy = (resTags, newTags) => {
    let mergeTags = [];
    let tempResTags = [];
    let resourceTags = resTags;

    if (resourceTags.length > 0) {
      const reg = /'/g;
      resourceTags = resourceTags.replace(reg, '"');
      resourceTags = JSON.parse(resourceTags);
    }
    let index = 0;

    for (const [resName, resValue] of Object.entries(resourceTags)) {
      const resourceTag = {
        id: index,
        name: resName,
        value: resValue,
        replaced: false,
      };
      tempResTags = [...tempResTags, resourceTag];
      index++;
    }

    tempResTags.forEach((resTagElement) => {
      newTags.forEach((newTagElement) => {
        if (
          resTagElement.name.toLowerCase() === newTagElement.name.toLowerCase()
        ) {
          if (newTagElement.value !== "") {
            resTagElement.replaced = true;
          }
          if (newTagElement.remove) {
            resTagElement.replaced = true;
          }
        }
      });
    });

    index = 0;
    tempResTags.forEach((resTagElement) => {
      if (!resTagElement.replaced && resTagElement.value !== "") {
        const newTag = {
          id: index,
          name: resTagElement.name,
          value: resTagElement.value,
          modified: false,
        };
        mergeTags = [...mergeTags, newTag];
        index = index + 1;
      }
    });

    newTags.forEach((resTagElement) => {
      if (!resTagElement.replaced && resTagElement.value !== "") {
        const newTag = {
          id: index,
          name: resTagElement.name,
          value: resTagElement.value,
          modified: true,
        };
        mergeTags = [...mergeTags, newTag];
        index = index + 1;
      }
    });

    return mergeTags;
  };

  const handleTaggingUpdate = (param, event) => {
    if (param.length === 1) {
      let resourceTags = param[0].tags;
      const reg = /'/g;
      resourceTags = resourceTags.replace(reg, '"');
      resourceTags = JSON.parse(resourceTags);

      let resTags = [];
      let index = 1;
      for (const [name, value] of Object.entries(resourceTags)) {
        const newTag = {
          id: index,
          name: name,
          value: value,
          modified: false,
          remove: false,
        };
        resTags = [...resTags, newTag];
        index = index + 1;
      }
      let match = false;
      tagTaxonomySet.forEach((taxonomyElement) => {
        match = false;
        resTags.forEach((resElement) => {
          if (typeof resElement.name == "undefined") {
            return true;
          }
          if (
            resElement.name.toLowerCase() === taxonomyElement.name.toLowerCase()
          ) {
            match = true;
            return true;
          }
        });
        if (!match) {
          const newTag = {
            id: index,
            name: taxonomyElement.name,
            value: "",
            modified: false,
            remove: false,
          };
          resTags = [...resTags, newTag];
          index = index + 1;
        }
      });

      setTitle(param[0].resName);
      setSubTitle(
        "Required tags will be appended to any already existiing tags"
      );
      setTagSet(resTags);
    }
    if (param.length > 1) {
      setTitle("Add Required Tags to Selected Resources");
      setSubTitle(
        "Required tags will be appended if they do not currently exist"
      );
      setMultiple(true);
      setTagSet(tagTaxonomySet);
    }
  };

  const processChanges = () => {
    setOpenTagging(false);
    if (updatedResources.length === 1) {
      setTitle(updatedResources[0].resName);
      setSubTitle("Confirm proposed tag changes and confirm");
      setExpand(true);
      let newTagSet = [];
      let newRenderTag = "";
      let index = 1;
      tagSet.forEach((tagElement) => {
        if (tagElement.value !== "") {
          const newTag = {
            id: index,
            name: tagElement.name,
            value: tagElement.value,
            modified: tagElement.modified,
          };
          newRenderTag =
            newRenderTag +
            "'" +
            tagElement.name +
            "':'" +
            tagElement.value +
            "' | ";
          newTagSet = [...newTagSet, newTag];
        }
      });
      newRenderTag = newRenderTag.slice(0, -1);
      updatedResources[0].newTags = newTagSet;
      updatedResources[0].newRenderTags = newRenderTag;
    }
    if (updatedResources.length > 1) {
      setTitle("Bulk Resource Tagging Confirmation");
      setSubTitle("Confirm proposed tag changes and confirm");
      setExpand(false);
      updatedResources.forEach((element) => {
        let newRenderTag = "";

        const mergedTags = mergeTagTaxonomy(element.labels, tagSet);

        mergedTags.forEach((tagElement) => {
          newRenderTag =
            newRenderTag +
            "'" +
            tagElement.name +
            "':'" +
            tagElement.value +
            "' | ";
        });
        newRenderTag = newRenderTag.slice(0, -1);
        element.newTags = mergedTags;
        element.newRenderTags = newRenderTag;
      });
    }
    setOpenTagConfirm(true);
  };

  const applyChanges = () => {
    setOpenTagConfirm(false);
    setEnableTagUpdate(false);
    setProcessTags(true);
    setSelectionModel([]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (processTags) {
      const payload = {
        labels: {},
        resources: [],
      };
      if (updatedResources.length > 0) {
        updatedResources[0].newTags.forEach((label) => {
          if (label.modified === true) {
            payload.labels[label.name] = label.value;
          }
        });
      }

      updatedResources.forEach((resElement) => {
        const tagPayload = {
          asset: resElement.asset,
          project: resElement.project,
          zone: resElement.zone,
          labels: resElement.labels, //resElement.newTags,
          label_fingerprint:
            resElement.label_fingerprint === null
              ? ""
              : resElement.label_fingerprint,
          service: resElement.service,
          resource_type: resElement.resource_type,
        };
        //tagPayload.tags = resElement.newTags;
        payload.resources.push(tagPayload);
      });
      executeFetch("POST", tagUpdateUrl, rest.token, payload).then(
        (response) => {
          if (response) {
            if (response.isError) {
              console.log(response.error);
              setSnackbar({
                open: true,
                message: response.error,
                severity: "error",
              });
            } else {
            }
          }
        }
      );

      setEnableTagUpdate(true);
    }
  }, [processTags, rest.refreshToken]);

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    executeFetch("GET", tagTaxonomyUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } else {
          let newTags = [];
          let index = 1;
          response.data.forEach((element) => {
            const newTag = {
              id: index,
              name: element.tagName,
              value: "",
              modified: false,
              remove: false,
            };
            newTags = [...newTags, newTag];
            index += 1;
          });
          setTagTaxonomySet(newTags);

          executeFetch("GET", tagComplianceUrl, rest.token).then((response) => {
            if (response) {
              if (response.isError) {
                console.log(response.error);
                setSnackbar({
                  open: true,
                  message: response.error,
                  severity: "error",
                });
              } else {
                /*

                    Label Compliance Score Calculations

                  */
                let index = 0;
                let totalResCount = response.data.resources.length;
                let totalLabelCount = 0;
                let reqLabelCount =
                  response.data.resources.length * newTags.length;
                let formattedData = [];

                response.data.resources.forEach((element) => {
                  let text = element.asset_type;
                  let newtext = text.replace("googleapis.com/", "");
                  const myArray = newtext.split(".");
                  element.service = myArray[0];
                  element.resource_type = myArray[1];

                  element.countLabels = 0;
                  element.renderTags = "";

                  element.id = index;
                  index++;

                  if (JSON.stringify(element.labels) !== "{}") {
                    let labels = JSON.stringify(element.labels);
                    let a = labels.replace("{", "");
                    let b = a.replace("}", "");
                    let renderTags = b.replace(",", "|");

                    element.renderTags = renderTags;

                    let countLabels = 0;
                    let missingLabelCount = 0;

                    // Find all missing labels
                    let missingLabels = "";
                    newTags.forEach((label) => {
                      if (renderTags.indexOf(label.name) === -1) {
                        missingLabels = missingLabels + label.name + "|";
                        missingLabelCount = missingLabelCount + 1;
                      } else {
                        countLabels = countLabels + 1;
                      }
                    });

                    if (countLabels > 0) {
                      element.countLabels = countLabels;
                      totalLabelCount = totalLabelCount + element.countLabels;
                    } else {
                      element.countLabels = "0";
                    }
                    element.missingLabelCount = missingLabelCount;
                    element.missingLabels = missingLabels;

                    // Totals
                  } else {
                    element.countLabels = "0";
                    element.renderTags = "";
                    element.missingLabelCount = newTags.length;

                    let missingLabels = "";
                    newTags.forEach((label) => {
                      missingLabels = missingLabels + label.name + "|";
                    });

                    element.missingLabels = missingLabels;
                  }

                  formattedData.push(element);
                });

                let score = 0;
                if (reqLabelCount > 0) {
                  score = (totalLabelCount / reqLabelCount) * 100;
                  score = score.toFixed(0) + "%";
                }

                setPageState((old) => ({
                  ...old,
                  data: formattedData,
                  totalResources: totalResCount,
                  totalCompliance: score,
                  total: totalResCount,
                  loading: false,
                }));

                /*

                  END CODE BLOCK

                */
              }
            }
          });
        }
      }
    });
  }, [pageState.total, updatedResources, rest.refreshToken]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          component="main"
          sx={{ flexGrow: 1, mx: "auto" }}
        >
          <BasicSnackbar
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={handleClose}
            vertical="top"
            horizontal="right"
          />
          <Typography></Typography>
          <DataGrid
            rows={pageState.data}
            rowCount={pageState.total}
            columns={columns}
            loading={pageState.loading}
            sx={userTableStyles}
            disableColumnSelector={true}
            slots={{
              toolbar: CustomToolbar,
            }}
            checkboxSelection
            hideFooterPagination
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const updatedResources = pageState.data.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectionModel(ids);
              setUpdatedResources(updatedResources);
            }}
            selectionModel={selectionModel}
          />
          <BulkAdd
            open={openTagging}
            onClose={() => setOpenTagging(false)}
            tagSet={tagSet}
            setTagSet={setTagSet}
            processChanges={processChanges}
            title={title}
            subTitle={subTitle}
            multiple={multiple}
            isAzure={false}
          />
          <TagConfirm
            open={openTagConfirm}
            onClose={() => setOpenTagConfirm(false)}
            updatedResources={updatedResources}
            applyChanges={applyChanges}
            title={title}
            subTitle={subTitle}
            expand={expand}
          />
          <TagApply
            open={openTagApply}
            onClose={() => setOpenTagApply(false)}
            tagSet={tagSet}
            setTagSet={setTagSet}
            title={title}
            subTitle={subTitle}
          />
          <Inventory open={openInventory} />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default GCPTagDataTable;
