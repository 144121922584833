import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "../components/BasicSnackbar";
import Box from "@mui/material/Box";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { tokenValid } from "../helpers/tokenValid";
import IconButton from "@mui/material/IconButton";
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from "@mui/material/Tooltip";

const userTableStyles = {
  height: "1000px",
};

const AdminAlertDataTable = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  const [alertsData, setAlertsData] = useState({
    data: [],
    isLoading: true,
  });
  // State object, properties and validation methods used for editing existing taxonomy...
  const [alertData, setAlertData] = useState({
    id: 0,
    subscription:"",
    status: 0,
    alert_type:"",
    alert_text: "",
    date_create: ""
  });
  const [refreshAlertData, setRefreshAlertData] = useState(true);
  

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // const taxonomyUrl = process.env.REACT_APP_BACKEND_URI;
  // const taxonomyInsertUrl = taxonomyUrl + "/api/add_taxonomy";
  // const taxonomyDeleteUrl = taxonomyUrl + "/api/delete_taxonomy";


  let providerUrl = "";
  if (rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    if (refreshAlertData) {
      const alertsUrl = providerUrl + "/api/alerts?alert=1";
      executeFetch("GET", alertsUrl, rest.token).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          } 
          
          let data = response.data;          
          setAlertsData({ data: data, isLoading: false });
          setRefreshAlertData(false);
        }
      });
    }
  }, [executeFetch, refreshAlertData, rest.refreshToken]);

  const columns = [
    {
      field: "edit",
      headerName: "Close",
      width: 20,
      renderCell: (cellValues) => {
        return (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              onClick={(event) => {
                handleRowEditClick(event, cellValues);
              }}
            >
              <UpdateIcon />
            </IconButton>
          </Tooltip>
        );
      },
      disableClickEventBubbling: true,
    },
    { field: "alert_type", headerName: "Alert Type", width: 300, editable: false },
    { field: "alert_text", headerName: "Alert Text", width: 400, editable: false },
    { field: "status", headerName: "Status", width: 300, editable: false },
  ];

  const handleRowEditClick = (event, cellValues) => {

    const alertUpdateUrl = providerUrl + "/api/update_alert?alert="+ cellValues.id;
    var payload = {
      id: cellValues.id,
      subscription: cellValues.subscription,
      status: 0,
      alert_type: cellValues.alert_type,
      alert_text: cellValues.alert_text,
      date_create: cellValues.date_create,
  }

    executeFetch("PUT", alertUpdateUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        } 
        
        let data = response.data;          
        setAlertsData({ data: data, isLoading: false });
        setRefreshAlertData(false);
      }
    });
  };

  return (
    <>
      <div>
        <ThemeProvider theme={theme}>

          <Box
            component="main"
            sx={{ flexGrow: 1, mx: "auto" }}
          >
            <BasicSnackbar
              open={snackbar.open}
              severity={snackbar.severity}
              message={snackbar.message}
              onClose={handleClose}
              vertical="top"
              horizontal="right"
            />
            <DataGrid
              rows={alertsData.data}
              rowCount={alertsData.data.length}
              columns={columns}
              loading={alertsData.loading}
              sx={userTableStyles}              
              disableColumnSelector={true}
              disableMultipleSelection={true}
              columnVisibilityModel={{
                id: false,
                subscription:false
              }}
            />

          </Box>           
        </ThemeProvider>
      </div>
    </>
  );
};

export default AdminAlertDataTable;
