import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { modalStyles } from "./styles";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../../styles/theme";

const PopupModal = ({ open, onClose, title, subTitle, content }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={modalStyles.wrapper}>
        <Typography
          variant="h6"
          component="h2"
        >
          {title}
        </Typography>
        <Typography sx={{ mt: 2 }}>{subTitle}</Typography>
        {content}
        <Box sx={modalStyles.buttons}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={hoverStyles}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupModal;
