import GCPTaskData from "../gcp-components/GCPTaskData";
import { AuthenticatedTemplate } from "@azure/msal-react";

export function TasksPage() {
  return (
    <>
      <AuthenticatedTemplate>
        <GCPTaskData />
      </AuthenticatedTemplate>
    </>
  );
}
