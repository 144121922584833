import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import BasicSnackbar from "../components/BasicSnackbar";
import { renderCellExpand } from "../components/RenderCellExpand";
import { useAuth } from "../authContext";
import executeFetch from "../helpers/executeFetch";
import { tokenValid } from "../helpers/tokenValid";

const columns = [
  {
    field: "friendlyName",
    headerName: "Name",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "subscription",
    headerName: "Subscription",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "resName",
    headerName: "Resource Name",
    width: 250,
    renderCell: renderCellExpand,
  },
  { field: "resLocation", headerName: "Resource Location", width: 150 },
  {
    field: "resGroup",
    headerName: "Resource Group",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    field: "score",
    headerName: "Compliance Score",
    width: 150,
    description: "Score based on ratio of resource tags to required tags",
  },
  {
    field: "tagCount",
    headerName: "Tag Count",
    width: 100,
    description: "Number of tags currently on resource",
  },
  {
    field: "renderTags",
    headerName: "Tags",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "missingTagCount",
    headerName: "Missing Count",
    width: 120,
    description: "Number of recommended tags missing on resource",
  },
  {
    field: "renderMissingTags",
    headerName: "Missing Tags",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "resType",
    headerName: "Resource Type",
    width: 400,
    renderCell: renderCellExpand,
  },
];
const userTableStyles = {
  height: "1000px",
};

const TagDataTable = ({ isWriter }) => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  
  let urlParameters = "";
  if (rest.subscription && rest.subscription !== "") {
    urlParameters = urlParameters + "tenant=" + rest.subscription.tenant;
  }

  let providerUrl = "";
  if (rest.provider && rest.provider !== "") {
    providerUrl = rest.provider.url;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: "flex" }}>
        <GridToolbarQuickFilter sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
        <GridToolbarExport
          variant="contained"
          sx={{ mr: 2, pt: 0.8, pb: 0.8 }}
        />
        <Typography variant="h6">
          Last Tag Inventory: {!tagComplianceData.isLoading ? tagComplianceData.data[0].date : null}{" "}
          UTC
        </Typography>
        <GridToolbarDensitySelector sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
      </GridToolbarContainer>
    );
  }

  const [tagComplianceData, setTagComplianceData] = useState({
    data: [],
    isLoading: true,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({open:false});
  };

  useEffect(() => {
    if(!tokenValid(rest.token)) {
      rest.setRefreshToken(true);
      return;
    }
    const tagComplianceUrl =
      providerUrl +
      "/api/tagging_tenant?" +
      urlParameters;
    executeFetch("GET", tagComplianceUrl, rest.token).then((response) => {
      if (response) {
        if (response.isError) {
          console.log(response.error);
          setSnackbar({
            open: true,
            message: response.error,
            severity: "error",
          });
        }
        else{
          setTagComplianceData({data: response.data, isLoading: false});
        }
      }
    });
  }, [executeFetch, rest.refreshToken]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <Typography></Typography>
        <DataGrid
          rows={tagComplianceData.data}
          rowCount={tagComplianceData.data.length}
          columns={columns}
          loading={tagComplianceData.isLoading}
          sx={userTableStyles}
          disableColumnSelector={true}
          checkboxSelection
          slots={{
            toolbar: CustomToolbar,
          }}
          hideFooterPagination
        />
      </Box>
    </>
  );
};

export default TagDataTable;
