import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANTID,
    redirectUri: process.env.REACT_APP_FRONTEND_URI,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      LogLevel: "Error",
    },
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_APP_SCOPE],
};

export const tokenRequest = {
  scopes: [process.env.REACT_APP_APP_SCOPE],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const appRoles = {
  Readers: "readers",
  Writers: "writers",
  Admins: "admins",
};
