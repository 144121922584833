import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import executeFetch from "../helpers/executeFetch";
import { useAuth } from "../authContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

const GCPAdminBulkTaxonomyAddTable = () => {
  const { ...rest } = useAuth();
  const [state, setState] = useState({
    loading: false,
    fileName: "",
    file: "",
    errorMsg: "",
    invalidFile: true,
    showAlert: false,
  });
  //setIsLoading(false);

  const fileReader = new FileReader();

  const refreshTaxonomyData = () => {};
  const taxonomyUrl = process.env.REACT_APP_BACKEND_URI;
  const taxonomyInsertUrl = taxonomyUrl + "/api/add_taxonomy";

  useEffect(() => {
    refreshTaxonomyData();
  }, [state]);

  const csvFileToArray = (string) => {
    var array = string.toString().split(/\r\n|\r|\n/);

    var data = [];

    let index = 0;
    for (const r of array) {
      if (index > 0) {
        let row = r.toString().split(",");
        data.push(row);
      }
      index++;
    }

    console.log({ data });

    // const payload = {
    //   taxonomy: [],
    // };
    let payload = "";
    for (const r of data) {
      if (r[0] !== "") {
        payload = {
          tagName: r[0],
          aliases: r[1],
          description: r[2],
          isRequired: r[3] === "1" ? 1 : 0,
          finOps: r[4] === "1" ? 1 : 0,
          operations: r[5] === "1" ? 1 : 0,
          patching: r[6] === "1" ? 1 : 0,
        };
        //payload.taxonomy.push(p);
      }

      executeFetch("POST", taxonomyInsertUrl, rest.token, payload)
        .then((response) => {
          setState((prevState) => {
            return { ...prevState, loading: false };
          });
        })
        .catch((response) => {
          setState((prevState) => {
            return { ...prevState, loading: false };
          });
        });
    }
  };

  const handleOnChange = (e) => {
    var valid = false;

    fileReader.onload = function (event) {
      const text = event.target.result;

      var array = text.toString().split(/\r\n|\r|\n/);

      for (const r of array) {
        valid = r.split(",").length - 1 === 6;

        if (!valid) {
          setState((prevState) => {
            return {
              ...prevState,
              errorMsg: "File Format Is Invalid",
              invalidFile: true,
              showAlert: true,
            };
          });
          break;
        }
      }

      if (valid) {
        setState((prevState) => {
          return {
            ...prevState,
            file: e.target.files[0],
            fileName: e.target.files[0].name,
            errorMsg: "",
            invalidFile: false,
            showAlert: false,
          };
        });
      }
    };

    fileReader.readAsText(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    setState((prevState) => {
      return { ...prevState, loading: true };
    });

    if (state.file) {
      fileReader.onload = function (event) {
        const text = event.target.result;

        csvFileToArray(text);
      };

      fileReader.readAsText(state.file);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={{ align: "center", textAlign: "center" }}>
          <h1 style={{ color: "red" }}></h1>
          <Typography
            style={{ marginRight: 10 }}
            variant="h6"
          >
            Import Taxonomy CSV File
          </Typography>
          <br></br>
          {!state.loading ? (
            <form>
              <div className="flex space-x-3 justify-center">
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={hoverStyles}
              >
                Select CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={(e) => handleOnChange(e)}
                />
              </Button>
              <Button
                variant="outlined"
                sx={hoverStyles}
                color="primary"
                disabled={state.invalidFile}
                onClick={(e) => handleOnSubmit()}
              >
                IMPORT CSV
              </Button>
              </div>
            </form>
          ) : null}
          <Box>
            <i>{state.fileName}</i>
          </Box>
          <br />

          {state.loading ? <CircularProgress color="secondary" /> : null}

          <Box sx={{ flexGrow: 1 }}>
            {state.showAlert ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs
                ></Grid>
                <Grid
                  item
                  xs={3}
                >
                  <Item>
                    <Alert severity="error">
                      <Typography variant="h6">{state.errorMsg}</Typography>
                    </Alert>
                  </Item>
                </Grid>
                <Grid
                  item
                  xs
                ></Grid>
              </Grid>
            ) : null}
            <h1 style={{ color: "red" }}></h1>
            <Typography
              style={{ marginRight: 10 }}
              variant="h6"
            >
              File Schema
            </Typography>
            <br></br>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs
              ></Grid>
              <Grid
                item
                xs={6}
              >
                <Item>
                  <Typography variant="subtitle1">
                    tagName: must be lower case, only dash ( - ) allowed
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    aliases: No commas, space delimited
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    description: No commas, space delimited
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    isRequired: 0 for false 1 for true
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    finOps: 0 for false 1 for true
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    operations: 0 for false 1 for true
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    patching: 0 for false 1 for true
                  </Typography>
                  <br />
                </Item>
              </Grid>
              <Grid
                item
                xs
              ></Grid>
            </Grid>
          </Box>
        </div>
      </ThemeProvider>
    </>
  );
};

export default GCPAdminBulkTaxonomyAddTable;
