import TenantResources from "../azure-components/TenantResources";
import GCPTenantResources from "../gcp-components/GCPTenantResources";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useAuth } from "../authContext";

export function TenantPage({ isWriter }) {
  const { ...rest } = useAuth();
  return (
    <>
      <AuthenticatedTemplate>
        {rest.provider.provider === "Azure" ? <TenantResources /> : null}
        {rest.provider.provider === "GCP" ? <GCPTenantResources /> : null}
      </AuthenticatedTemplate>
    </>
  );
}
